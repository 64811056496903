import React from "react";
import { Container, Row, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Grid from "./Grid";

const CompanyNews = () => {
  return (
    <div>
      <div className="innerbanner">
        <div className="innerHeader d-none d-lg-block">
          <Navbar expand="lg">
            <Container fluid>
              <Navbar.Brand
                as={Link}
                to="/customer-future"
                className="bold poppins white"
              >
                Company
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                  <Nav.Link as={Link} to="/what-hero">
                    About us
                  </Nav.Link>
                  {/* <Nav.Link as={Link} to="/how-it-works">
                    Our Team
                  </Nav.Link> */}
                  <Nav.Link as={Link} to="/newsroom">
                    News
                  </Nav.Link>
                  <Nav.Link as={Link} to="/blog">
                    Blog
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
        <Container>
          <h1 className="white fz64 bold poppins"> Hero News </h1>
        </Container>
      </div>
      <div className="sec_pd">
        <Container>
          <Row>
            <Grid />
            {/* {apiNewsData.map((item, index) => (
              <Col key={index} sm={12} md={4}>
                <div className="newsBox">
                  <div className="imageBox">Image</div>
                  <div className="newsBox_content">
                    <span className="black fz16 medium poppins">
                      {" "}
                      {getFormattedDate(item.created)}{" "}
                    </span>
                    <p className="mb-0 black fz24 medium poppins mt-1">
                      <Link
                        to="/newsroom/news/amazing-news-with-slug"
                        className="black text-decoration-none"
                      >
                        {item.title}
                      </Link>
                    </p>
                  </div>
                </div>
              </Col>
            ))} */}
          </Row>
        </Container>
        {/* <div className="text-center mT40">
          {isMore && (
            <button
              onClick={handleload}
              className="viewMore_text fz20 medium poppins black text-decoration-none bg-transparent border-0"
            >
              View more news
              <svg
                class="rightIcon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
              </svg>
            </button>
          )}
        </div> */}
      </div>
    </div>
  );
};

export default CompanyNews;

