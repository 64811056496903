import React, { useState, useEffect } from "react";
import { Col, Container, Image, Row, Nav, Navbar } from "react-bootstrap";
import { getNews } from "../api/News";
import { Link, useNavigate } from "react-router-dom";
import { getFormattedDate } from "../utils";

const Grid = ({ perPage = 9 }) => {
  const [newsItems, setNewsItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMore, setIsMore] = useState(true);
  const [baseURL, setBaseURL] = useState("");

  const navigate = useNavigate();

  const handleload = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    handleapiData();
  }, [currentPage]);

  const handleapiData = () => {
    getNews({
      page: currentPage,
      perPage,
    })
      .then((response) => {
        setNewsItems((previous) => [
          ...previous,
          ...response.data.data.newsList,
        ]);
        setBaseURL(response.data.data.s3Base);
        setIsMore(response.data.data.newsList.length === perPage);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleViewDetails = (type) => {
    type && navigate(`/newsroom/news/${type}`);
  }

  return (
    <>
      {newsItems?.length > 0 &&
        newsItems.map((item, index) => (
          <Col key={index} sm={12} md={4}>
            <div className="newsBox">
              <div className="imageBox" >
                <img src={`${baseURL}${item.banner}`} alt="NewsImage" className="img-fluid" />
              </div>
              <div className="newsBox_content" onClick={() => handleViewDetails(item?.slug)}>
                <span className="black fz16 medium poppins">
                  {getFormattedDate(item.created)}{" "}
                </span>
                <p className="mb-0 black fz24 medium poppins mt-1">
                  <Link
                    to={item?.slug ? `/newsroom/news/${item?.slug}` : ''}
                    className="black text-decoration-none"
                  >
                    {item.title}
                  </Link>
                </p>
              </div>
            </div>
          </Col>
        ))}
      <div className="text-center mT40">
        {isMore && (
          <button
            onClick={handleload}
            className="viewMore_text fz20 medium poppins black text-decoration-none bg-transparent border-0"
          >
            View more news
            <svg
              class="rightIcon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
            </svg>
          </button>
        )}

      </div>
    </>
  );
};
export default Grid;
