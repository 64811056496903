import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router";
import {
  getHeroEngagementAgreement,
  getHeroSignupProgress,
  heroForm,
} from "../api/hero";
import YesNoButtonComponent from "../components/YesNoButtonComponent.jsx";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";

const HeroSignupStep4 = () => {
  const navigate = useNavigate();
  const [activeState, setActiveState] = useState("showInstructions");
  const [heroData, setHeroData] = useState({});
  const [additionalInfo, setAdditionaInfo] = useState({});
  useEffect(() => {
    //get additional info
    getHeroEngagementAgreement().then((response) => {
      if (response.data.success) {
        setAdditionaInfo(response.data?.data);
      }
    });

    //get hero signup progress
    getHeroSignupProgress().then((response) => {
      if (response.data.success) {
        const heroRes = response.data.data;
        const hero = {
          height: "",
          weight: "",
          hairColor: "",
          eyeColor: "",
          race: "",
          sex: "",
          street: "",
          apt: "",
          city: "",
          state: "",
          country: "",
          zipcode: "",
          jacketSize: "",
          deviceModel: "",
          isStateLicense: "",
          stateLicense: "",
          isFirearmLicense: "",
          firearmLicense: "",
          isMedicalKnowledge: "",
          medicalKnowledge: "",
          isViolentSituation: "",
          violentSituation: "",
          isPhysicalTrainingSkill: "",
          physicalTrainingSkill: "",
          isPersonalArm: "",
          personalArm: "",
          isScaleFootWall: "",
          isCrawlTunnel: "",
          isClimb: "",
          isWalk: "",
          isCarryWeight: "",
          isLiftCarryWeight: "",
          isRunMileTime: "",
          isPushUps: "",
          isBenchPress: "",
          isSitUps: "",
          fitnessDescription: "",
        };
        //personal info
        heroRes?.personalInfo?.height &&
          (hero.height = heroRes?.personalInfo?.height);
        heroRes?.personalInfo?.weight &&
          (hero.weight = heroRes?.personalInfo?.weight);
        heroRes?.personalInfo?.hairColor &&
          (hero.hairColor = heroRes?.personalInfo?.hairColor);
        heroRes?.personalInfo?.eyeColor &&
          (hero.eyeColor = heroRes?.personalInfo?.eyeColor);

        //personal address
        heroRes?.personalAddress?.street &&
          (hero.street = heroRes?.personalAddress?.street);
        heroRes?.personalAddress?.apt &&
          (hero.apt = heroRes?.personalAddress?.apt);
        heroRes?.personalAddress?.city &&
          (hero.city = heroRes?.personalAddress?.city);
        heroRes?.personalAddress?.state &&
          (hero.state = heroRes?.personalAddress?.state);
        heroRes?.personalAddress?.country &&
          (hero.country = heroRes?.personalAddress?.country);
        heroRes?.personalAddress?.zipcode &&
          (hero.zipcode = heroRes?.personalAddress?.zipcode);

        //brand information

        heroRes?.brandInfo?.jacketSize &&
          (hero.jacketSize = heroRes?.brandInfo?.jacketSize);

        heroRes?.brandInfo?.deviceModel &&
          (hero.deviceModel = heroRes?.brandInfo?.deviceModel);

        //certificates
        hero.isStateLicense = heroRes?.certificates?.isStateLicense;
        heroRes?.certificates?.stateLicense &&
          (hero.stateLicense = heroRes?.certificates?.stateLicense);
        hero.isFirearmLicense = heroRes?.certificates?.isFirearmLicense;
        heroRes?.certificates?.firearmLicense &&
          (hero.firearmLicense = heroRes?.certificates?.firearmLicense);

        //genenralExperience
        hero.isMedicalKnowledge =
          heroRes?.genenralExperience?.isMedicalKnowledge;
        heroRes?.genenralExperience?.medicalKnowledge &&
          (hero.medicalKnowledge =
            heroRes?.genenralExperience?.medicalKnowledge);
        hero.isViolentSituation =
          heroRes?.genenralExperience?.isViolentSituation;
        heroRes?.genenralExperience?.violentSituation &&
          (hero.violentSituation =
            heroRes?.genenralExperience?.violentSituation);
        hero.isPhysicalTrainingSkill =
          heroRes?.genenralExperience?.isPhysicalTrainingSkill;
        heroRes?.genenralExperience?.physicalTrainingSkill &&
          (hero.physicalTrainingSkill =
            heroRes?.genenralExperience?.physicalTrainingSkill);
        hero.isPersonalArm = heroRes?.genenralExperience?.isPersonalArm;
        heroRes?.genenralExperience?.personalArm &&
          (hero.personalArm = heroRes?.genenralExperience?.personalArm);

        //medicalStatus
        hero.isMedical = heroRes?.medicalStatus?.isMedical;
        heroRes?.medicalStatus?.medical &&
          (hero.medical = heroRes?.medicalStatus?.medical);

        //fitnessRequirement
        hero.isJumpFootWall = heroRes?.fitnessRequirement?.isJumpFootWall;
        hero.isScaleFootWall = heroRes?.fitnessRequirement?.isScaleFootWall;
        hero.isCrawlTunnel = heroRes?.fitnessRequirement?.isCrawlTunnel;
        hero.isClimb = heroRes?.fitnessRequirement?.isClimb;
        hero.isWalk = heroRes?.fitnessRequirement?.isWalk;
        hero.isCarryWeight = heroRes?.fitnessRequirement?.isCarryWeight;
        hero.isLiftCarryWeight = heroRes?.fitnessRequirement?.isLiftCarryWeight;
        hero.isMaxRunningTime = heroRes?.fitnessRequirement?.isMaxRunningTime;
        hero.isRunMileTime = heroRes?.fitnessRequirement?.isRunMileTime;
        hero.isPushUps = heroRes?.fitnessRequirement?.isPushUps;
        hero.isBenchPress = heroRes?.fitnessRequirement?.isBenchPress;
        hero.isSitUps = heroRes?.fitnessRequirement?.isSitUps;
        hero.fitnessDescription =
          heroRes?.fitnessRequirement?.fitnessDescription;

        //set hero data
        setHeroData(hero);
      }
    });
  }, []);

  const heroValidationSchema = yup.object().shape({
    height: yup
      .number()
      .typeError("Height must be a number")
      .max(8, "Enter valid height")
      .required("Please enter height"),
    weight: yup
      .number()
      .typeError("Weight must be a number")
      .max(400, "Enter valid weight")
      .required("Please enter weight"),
    hairColor: yup.string().required("Please enter hair color"),
    eyeColor: yup.string().required("Please enter eye color"),
    race: yup.string().required("Please enter race"),
    sex: yup.string().required("Please select sex"),
    street: yup.string().required("Please enter street"),
    apt: yup.string().required("Please enter apt"),
    city: yup.string().required("Please enter city"),
    state: yup.string().required("Please enter state"),
    country: yup.string().required("Please enter country"),
    zipcode: yup.string().required("Please enter zipcode"),
    jacketSize: yup.string().required("Please enter jacket size"),
    deviceModel: yup.string().required("Please enter device model"),
    isStateLicense: yup.boolean().required("Please select yes/no"),
    stateLicense: yup.string().when("isStateLicense", {
      is: true,
      then: (schema) => schema.required("Please enter state"),
      otherwise: (schema) => schema.notRequired(),
    }),
    isFirearmLicense: yup.bool().required("Please select yes/no"),
    firearmLicense: yup.string().when("isFirearmLicense", {
      is: true,
      then: (schema) => schema.required("Please enter state"),
      otherwise: (schema) => schema.notRequired(),
    }),
    isMedicalKnowledge: yup.bool().required("Please select yes/no"),
    medicalKnowledge: yup.string().when("isMedicalKnowledge", {
      is: true,
      then: (schema) => schema.required("Please enter medical knowledge"),
      otherwise: (schema) => schema.notRequired(),
    }),
    isViolentSituation: yup.bool().required("Please select yes/no"),
    violentSituation: yup.string().when("isViolentSituation", {
      is: true,
      then: (schema) => schema.required("Please enter details"),
      otherwise: (schema) => schema.notRequired(),
    }),
    isPhysicalTrainingSkill: yup.bool().required("Please select yes/no"),
    physicalTrainingSkill: yup.string().when("isPhysicalTrainingSkill", {
      is: true,
      then: (schema) => schema.required("Please enter physical training "),
      otherwise: (schema) => schema.notRequired(),
    }),
    isPersonalArm: yup.bool().required("Please select yes/no"),
    personalArm: yup.string().when("isPersonalArm", {
      is: true,
      then: (schema) => schema.required("Please enter details"),
      otherwise: (schema) => schema.notRequired(),
    }),
    isMedical: yup.bool().required("Please select yes/no"),
    medical: yup.string().when("isMedical", {
      is: true,
      then: (schema) => schema.required("Please enter details"),
      otherwise: (schema) => schema.notRequired(),
    }),
    isScaleFootWall: yup.bool().required("Please select yes/no"),
    isCrawlTunnel: yup.bool().required("Please select yes/no"),
    isClimb: yup.bool().required("Please select yes/no"),
    isWalk: yup.bool().required("Please select yes/no"),
    isCarryWeight: yup.bool().required("Please select yes/no"),
    isLiftCarryWeight: yup.bool().required("Please select yes/no"),
    isRunMileTime: yup.bool().required("Please select yes/no"),
    isPushUps: yup.bool().required("Please select yes/no"),
    isBenchPress: yup.bool().required("Please select yes/no"),
    isSitUps: yup.bool().required("Please select yes/no"),
    fitnessDescription: yup.string(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: heroData,
    validationSchema: heroValidationSchema,
    onSubmit: async (values) => {
      const heroFormData = {
        personalInfo: {},
        personalAddress: {},
        brandInfo: {},
        certificates: {},
        genenralExperience: {},
        medicalStatus: {},
        fitnessRequirement: {},
      };
      //personal info
      values.height && (heroFormData.personalInfo.height = values.height);
      values.weight && (heroFormData.personalInfo.weight = values.weight);
      values.hairColor &&
        (heroFormData.personalInfo.hairColor = values.hairColor);
      values.eyeColor && (heroFormData.personalInfo.eyeColor = values.eyeColor);
      values.race && (heroFormData.personalInfo.race = values.race);
      values.sex && (heroFormData.personalInfo.sex = values.sex);

      //personal address
      values.street && (heroFormData.personalAddress.street = values.street);
      values.apt && (heroFormData.personalAddress.apt = values.apt);
      values.city && (heroFormData.personalAddress.city = values.city);
      values.state && (heroFormData.personalAddress.state = values.state);
      values.country && (heroFormData.personalAddress.country = values.country);
      values.zipcode && (heroFormData.personalAddress.zipcode = values.zipcode);

      //brand info
      values.jacketSize &&
        (heroFormData.brandInfo.jacketSize = values.jacketSize);
      values.deviceModel &&
        (heroFormData.brandInfo.deviceModel = values.deviceModel);

      //certificates
      heroFormData.certificates.isStateLicense = values.isStateLicense;
      !values.isStateLicense
        ? (heroFormData.certificates.stateLicense = "")
        : (heroFormData.certificates.stateLicense = values.stateLicense);

      heroFormData.certificates.isFirearmLicense = values.isFirearmLicense;
      !values.firearmLicense
        ? (heroFormData.certificates.firearmLicense = "")
        : (heroFormData.certificates.firearmLicense = values.firearmLicense);

      //genenralExperience
      heroFormData.genenralExperience.isMedicalKnowledge =
        values.isMedicalKnowledge;
      values.medicalKnowledge &&
        (heroFormData.genenralExperience.medicalKnowledge =
          values.medicalKnowledge);
      heroFormData.genenralExperience.isViolentSituation =
        values.isViolentSituation;
      values.violentSituation &&
        (heroFormData.genenralExperience.violentSituation =
          values.violentSituation);
      heroFormData.genenralExperience.isPhysicalTrainingSkill =
        values.isPhysicalTrainingSkill;
      values.physicalTrainingSkill &&
        (heroFormData.genenralExperience.physicalTrainingSkill =
          values.physicalTrainingSkill);
      heroFormData.genenralExperience.isPersonalArm = values.isPersonalArm;
      values.personalArm &&
        (heroFormData.genenralExperience.personalArm = values.personalArm);

      //medicalStatus
      heroFormData.medicalStatus.isMedical = values.isMedical;
      values.medical && (heroFormData.medicalStatus.medical = values.medical);

      //fitnessRequirement
      heroFormData.fitnessRequirement.isJumpFootWall = values.isJumpFootWall;
      heroFormData.fitnessRequirement.isScaleFootWall = values.isScaleFootWall;
      heroFormData.fitnessRequirement.isCrawlTunnel = values.isCrawlTunnel;
      heroFormData.fitnessRequirement.isClimb = values.isClimb;
      heroFormData.fitnessRequirement.isWalk = values.isWalk;
      heroFormData.fitnessRequirement.isCarryWeight = values.isCarryWeight;
      heroFormData.fitnessRequirement.isLiftCarryWeight =
        values.isLiftCarryWeight;
      heroFormData.fitnessRequirement.isMaxRunningTime =
        values.isMaxRunningTime;
      heroFormData.fitnessRequirement.isRunMileTime = values.isRunMileTime;
      heroFormData.fitnessRequirement.isPushUps = values.isPushUps;
      heroFormData.fitnessRequirement.isBenchPress = values.isBenchPress;
      heroFormData.fitnessRequirement.isSitUps = values.isSitUps;
      heroFormData.fitnessRequirement.fitnessDescription =
        values.fitnessDescription;

      try {
        const response = await heroForm(heroFormData);
        if (response.data.success) {
          navigate("/hero-signup-progress");
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleRadioChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value === "true");
    let name = e.target.name.replace("is", "");
    if (e.target.value !== "true") {
      formik.setFieldValue(`${name[0].toLowerCase()}${name.slice(1)}`, "");
      formik.setErrors({
        ...formik.errors,
        [`${name[0].toLowerCase()}${name.slice(1)}`]: null,
      });
    }
    console.log(formik.errors);
  };

  return (
    <Fragment>
      {activeState === "showInstructions" && (
        <div className="customer_signup sec_pd">
          <Container>
            <div className="uploadyourProfile">
              <div className="processTitle black medium fz20 poppins text-center mB15">
                <NavLink
                  className="d-flex align-items-center text-decoration-none text-dark"
                  to="/hero-signup-progress"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14 18L8 12L14 6"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>{" "}
                  Back
                </NavLink>
                Hero Engagement Agreement{" "}
              </div>
              <p className="fz16 regular poppins black text-center">
                Between Hero Security (NY) LLC or the <br></br>‘Company’ and
                you, or the ‘Hero’
              </p>

              <NavLink
                className="mT30 mB30 text-center purple d-block text-decoration-none"
                onClick={() => {
                  setActiveState("showAdditionalInformation");
                }}
              >
                {" "}
                Additional information{" "}
              </NavLink>
              <div>
                {" "}
                Congratulations! You are almost ready to become a Hero ... Due
                to the Company's new operations, you will be introduced to and
                asked to agree to a series of policies and procedures designed
                to enable the Company to provide safe and efficient services to
                our clients, as well as to protect each Hero and our business.
                In this Agreement, we will go over some of the things we need to
                get you fully on board and ready to participate in our exclusive
                personal safety services.
              </div>

              <div className="btnRow w-50 m-auto">
                <div
                  className="uploadbtn mt-3"
                  onClick={() => {
                    setActiveState("showHeroForm");
                  }}
                >
                  Continue
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}

      {activeState === "showAdditionalInformation" && (
        <div className="customer_signup sec_pd">
          <Container>
            <div className="uploadyourProfile">
              <div className="processTitle black medium fz20 poppins text-center mB15">
                {additionalInfo?.title && parse(additionalInfo?.title)}{" "}
              </div>

              <div>
                {additionalInfo?.description &&
                  parse(additionalInfo?.description)}
              </div>

              <div className="btnRow w-50 m-auto">
                <div
                  className="uploadbtn mt-3"
                  onClick={() => {
                    setActiveState("showInstructions");
                  }}
                >
                  Back
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}

      {activeState === "showHeroForm" && (
        <div className="heroApplication sec_pd">
          <Container>
            <Form>
              <h4 className="fz20 poppins black semibold text-center mb-4">
                <NavLink
                  className="d-flex align-items-center text-decoration-none text-dark"
                  to=""
                  onClick={() => {
                    setActiveState("showInstructions");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14 18L8 12L14 6"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>{" "}
                  Back
                </NavLink>
                Hero form
              </h4>
              <div className="personalInfo">
                <p className="blue semibold fz18 poppins">
                  {" "}
                  Personal Information{" "}
                </p>
                <Row className="mb-4">
                  <Col>
                    <div className="d-flex  justify-content-between">
                      <label className="black fz18 regular poppins ">
                        Height(ft)
                      </label>
                      <input
                        name="height"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.height}
                      />
                    </div>
                    {formik.touched.height && formik.errors.height ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.height}
                      </div>
                    ) : null}
                  </Col>
                  <Col>
                    <div className="d-flex  justify-content-between">
                      <label className="black fz18 regular poppins ">
                        Weight(lb)
                      </label>
                      <input
                        name="weight"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.weight}
                      />
                    </div>
                    {formik.touched.weight && formik.errors.weight ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.weight}
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <div className="d-flex  justify-content-between">
                      <label className="black fz18 regular poppins me-5">
                        Hair Color
                      </label>
                      <input
                        name="hairColor"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.hairColor}
                      />
                    </div>
                    {formik.touched.hairColor && formik.errors.hairColor ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.hairColor}
                      </div>
                    ) : null}
                  </Col>
                  <Col>
                    <div className="d-flex  justify-content-between">
                      <label className="black fz18 regular poppins me-5">
                        Eye Color
                      </label>
                      <input
                        name="eyeColor"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.eyeColor}
                      />
                    </div>
                    {formik.touched.eyeColor && formik.errors.eyeColor ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.eyeColor}
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex  justify-content-between">
                      <label className="black fz18 regular poppins me-5">
                        Race
                      </label>
                      <input
                        name="race"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.race}
                      />
                    </div>
                    {formik.touched.race && formik.errors.race ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.race}
                      </div>
                    ) : null}
                  </Col>
                  <Col>
                    <div className="d-flex  justify-content-between">
                      <label className="black fz18 regular poppins me-5">
                        Sex
                      </label>
                      <select
                        name="sex"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.sex}
                      >
                        <option value="">Please select</option>
                        <option value="MALE">Male</option>
                        <option value="FEMALE">Female</option>
                        <option value="OTHER">Other</option>
                      </select>
                    </div>
                    {formik.touched.sex && formik.errors.sex ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.sex}
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <hr className="mt-4 mb-4"></hr>
              </div>

              <div className="personalInfo">
                <p className="blue semibold fz18 poppins"> Personal address </p>
                <Row className="mb-4 streetRow">
                  <Col>
                    <div className="d-flex justify-content-between align-items-center">
                      <label className="black fz18 regular poppins me-5">
                        {" "}
                        Street{" "}
                      </label>
                      <input
                        name="street"
                        className="w-100"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.street}
                      />
                    </div>
                    {formik.touched.street && formik.errors.street ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.street}
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <div className="d-flex  justify-content-between">
                      <label className="black fz18 regular poppins me-5">
                        {" "}
                        Apt{" "}
                      </label>
                      <input
                        name="apt"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.apt}
                      />
                    </div>
                    {formik.touched.apt && formik.errors.apt ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.apt}
                      </div>
                    ) : null}
                  </Col>
                  <Col>
                    <div className="d-flex  justify-content-between">
                      <label className="black fz18 regular poppins me-5">
                        Country
                      </label>
                      <input
                        name="country"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.country}
                      />
                    </div>
                    {formik.touched.country && formik.errors.country ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.country}
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <div className="d-flex  justify-content-between">
                      <label className="black fz18 regular poppins me-5">
                        {" "}
                        State{" "}
                      </label>
                      <input
                        name="state"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.state}
                      />
                    </div>
                    {formik.touched.state && formik.errors.state ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.state}
                      </div>
                    ) : null}
                  </Col>
                  <Col>
                    <div className="d-flex  justify-content-between">
                      <label className="black fz18 regular poppins me-5">
                        City
                      </label>
                      <input
                        name="city"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.city}
                      />
                    </div>
                    {formik.touched.city && formik.errors.city ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.city}
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex  justify-content-between">
                      <label className="black fz18 regular poppins me-5">
                        {" "}
                        Zip Code{" "}
                      </label>
                      <input
                        name="zipcode"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.zipcode}
                      />
                    </div>
                    {formik.touched.zipcode && formik.errors.zipcode ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.zipcode}
                      </div>
                    ) : null}
                  </Col>
                  <Col></Col>
                </Row>
                <hr className="mt-4 mb-4"></hr>
              </div>

              <div className="personalInfo">
                <p className="blue semibold fz18 poppins">
                  {" "}
                  Brand Information{" "}
                </p>
                <p className="grayopacity regular fz18 poppins mb-4 grayTitle">
                  Please insert exact information, we will send products of our
                  brand so that you can reflect and feel like a Hero.
                </p>
                <Row className="mb-4 ">
                  <Col>
                    <div className="d-flex justify-content-start align-items-start">
                      <label className="black fz18 regular poppins me-5">
                        {" "}
                        Jacket Size{" "}
                      </label>
                      <input
                        name="jacketSize"
                        className="w-25"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.jacketSize}
                      />
                    </div>
                    {formik.touched.jacketSize && formik.errors.jacketSize ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.jacketSize}
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="d-flex  justify-content-start">
                      <label className="black fz18 regular poppins me-5">
                        {" "}
                        Device model{" "}
                      </label>
                      <input
                        name="deviceModel"
                        className="w-25"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.deviceModel}
                      />
                    </div>
                    {formik.touched.deviceModel && formik.errors.deviceModel ? (
                      <div className="text-danger field-error fz12">
                        {formik.errors.deviceModel}
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <hr className="mt-4 mb-4"></hr>
              </div>

              <div className="personalInfo">
                <p className="blue semibold fz18 poppins mb-4">
                  {" "}
                  Licenses/Certifications{" "}
                </p>
                <div className="mb-5">
                  <span className="black medium fz18 poppins">
                    Please state if you have a driver’s license and, if so, in
                    which state.
                  </span>
                  <div className="d-flex justify-content-end">
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isStateLicense"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isStateLicense}
                    />

                    {formik.values.isStateLicense && (
                      <div className="d-flex ms-3">
                        <input
                          style={{
                            minWidth: "60px",
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                          type="text"
                          size="1"
                          name="stateLicense"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="state"
                          value={formik.values.stateLicense}
                        />
                      </div>
                    )}
                  </div>
                  {formik.touched.stateLicense && formik.errors.stateLicense ? (
                    <div className="text-danger field-error fz12 text-end">
                      {formik.errors.stateLicense}
                    </div>
                  ) : null}
                </div>
                <div className="mb-3">
                  <span className="black medium fz18 poppins">
                    The security position is disarmed. Firearms will not be
                    carried to provide security. However, please indicate if you
                    are licensed to carry firearms.
                  </span>
                  <div className="d-flex justify-content-end">
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isFirearmLicense"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isFirearmLicense}
                    />

                    {formik.values.isFirearmLicense && (
                      <div className="d-flex ms-3">
                        <input
                          type="text"
                          size="1"
                          name="firearmLicense"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="state"
                          value={formik.values.firearmLicense}
                        />
                      </div>
                    )}
                  </div>

                  {formik.touched.firearmLicense &&
                  formik.errors.firearmLicense ? (
                    <div className="text-danger field-error fz12 text-end">
                      {formik.errors.firearmLicense}
                    </div>
                  ) : null}
                </div>
                <hr className="mt-4 mb-4"></hr>
              </div>

              <div className="personalInfo generalIfo">
                <p className="blue semibold fz18 poppins mb-4">
                  {" "}
                  General Experience{" "}
                </p>
                <div className="mb-4">
                  <span className="black medium fz18 poppins mb-3 d-block">
                    Please list and describe if you have any emergency medical
                    knowledge or training, such as CPR or first aid.
                  </span>
                  <div className="d-flex align-items-center generalinfoRow">
                    <div className="w-100 me-4 inputdiv order2">
                      <input
                        name="medicalKnowledge"
                        className="w-100"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={formik.values.isMedicalKnowledge === false}
                        value={formik.values.medicalKnowledge}
                      />

                      {formik.touched.medicalKnowledge &&
                        formik.errors.medicalKnowledge &&
                        formik.values.isMedicalKnowledge && (
                          <div className="text-danger field-error fz12">
                            {formik.errors.medicalKnowledge}
                          </div>
                        )}
                    </div>

                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isMedicalKnowledge"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isMedicalKnowledge}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <span className="black medium fz18 poppins mb-3 d-block">
                    Please describe any training or experience in conflict
                    resolution or defusing a violent situation.
                  </span>
                  <div className="d-flex align-items-center generalinfoRow">
                    <div className="w-100 me-4 inputdiv order2">
                      <input
                        type="text"
                        name="violentSituation"
                        className="w-100"
                        onChange={formik.handleChange}
                        disabled={formik.values.isViolentSituation === false}
                        onBlur={formik.handleBlur}
                        value={formik.values.violentSituation}
                      />

                      {formik.touched.violentSituation &&
                        formik.errors.violentSituation &&
                        formik.values.isViolentSituation && (
                          <div className="text-danger field-error fz12">
                            {formik.errors.violentSituation}
                          </div>
                        )}
                    </div>
                    <div className="d-flex justify-content-end statusItem">
                      <YesNoButtonComponent
                        formik={formik}
                        fieldName="isViolentSituation"
                        handleRadioChange={handleRadioChange}
                        value={formik.values.isViolentSituation}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4">
                  <span className="black medium fz18 poppins mb-3 d-block">
                    Please describe any other physical training or skills
                    (defensive holds, combat training, tactical training, etc.)
                    you have that will support your role providing personal
                    security services.
                  </span>
                  <div className="d-flex align-items-center generalinfoRow">
                    <div className="w-100 me-4 inputdiv order2">
                      <input
                        type="text"
                        name="physicalTrainingSkill"
                        className="w-100"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={
                          formik.values.isPhysicalTrainingSkill === false
                        }
                        value={formik.values.physicalTrainingSkill}
                      />

                      {formik.touched.physicalTrainingSkill &&
                        formik.errors.physicalTrainingSkill &&
                        formik.values.isPhysicalTrainingSkill && (
                          <div className="text-danger field-error fz12">
                            {formik.errors.physicalTrainingSkill}
                          </div>
                        )}
                    </div>
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isPhysicalTrainingSkill"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isPhysicalTrainingSkill}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <span className="black medium fz18 poppins mb-3 d-block">
                    Although the personal security position is un-armed, please
                    describe if you have any firearms, weapons, taser, etc.
                  </span>
                  <div className="d-flex align-items-center generalinfoRow">
                    <div className="w-100 me-4 inputdiv order2">
                      <input
                        type="text"
                        name="personalArm"
                        className="w-100"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={formik.values.isPersonalArm === false}
                        value={formik.values.personalArm}
                      />

                      {formik.touched.personalArm &&
                        formik.errors.personalArm &&
                        formik.values.isPersonalArm && (
                          <div className="text-danger field-error fz12">
                            {formik.errors.personalArm}
                          </div>
                        )}
                    </div>
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isPersonalArm"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isPersonalArm}
                    />
                  </div>
                </div>
                <hr className="mt-4 mb-4"></hr>
              </div>

              <div className="personalInfo generalIfo">
                <p className="blue semibold fz18 poppins mb-4">
                  {" "}
                  Medical Status{" "}
                </p>
                <div className="mb-4">
                  <span className="black medium fz18 poppins mb-3 d-block">
                    Do you take any medication that affects your nervous system
                    or cognitive abilities? or otherwise impairs your ability to
                    engage in strenuous physical activity
                  </span>
                  <div className="d-flex align-items-center generalinfoRow">
                    <div className="w-100 me-4 inputdiv order2">
                      <input
                        type="text"
                        name="medical"
                        className="w-100"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={formik.values.isMedical === false}
                        value={formik.values.medical}
                      />
                      {formik.touched.medical &&
                        formik.errors.medical &&
                        formik.values.isMedical && (
                          <div className="text-danger field-error fz12">
                            {formik.errors.medical}
                          </div>
                        )}
                    </div>
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isMedical"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isMedical}
                    />
                  </div>
                </div>
                <hr className="mt-4 mb-4"></hr>
              </div>

              <div className="personalInfo">
                <p className="blue semibold fz18 poppins mb-4">
                  {" "}
                  Physical Fitness Requirements{" "}
                </p>
                <p className="grayopacity regular fz18 poppins mb-4 grayTitle">
                  There are required physical and mental skills that are
                  essential functions needed to perform this position,
                  summarized on the position description attached at the end of
                  this questionnaire. Please state whether you can perform these
                  physical and mental skills required for the position. If you
                  need any assistance or accommodation to do so, please describe
                  or demonstrate how you would perform the physical and mental
                  requirements of this position with an accommodation.
                </p>
                <div className="mb-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="black regular fz18 poppins d-block">
                      Scale four-foot wall
                    </span>
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isScaleFootWall"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isScaleFootWall}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="black regular fz18 poppins d-block">
                      Crawl through a tunnel/low lying obstacle for 10 feet
                    </span>
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isCrawlTunnel"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isCrawlTunnel}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="black regular fz18 poppins d-block">
                      Climb and go down stairs
                    </span>
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isClimb"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isClimb}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="black regular fz18 poppins d-block">
                      Walk a balance beam
                    </span>
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isWalk"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isWalk}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="black regular fz18 poppins d-block">
                      Carry 50 lbs weight up a flight of stairs
                    </span>
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isCarryWeight"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isCarryWeight}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="black regular fz18 poppins d-block">
                      Lift and carry and set down 50 lbs weight
                    </span>
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isLiftCarryWeight"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isLiftCarryWeight}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="black regular fz18 poppins d-block">
                      Run a mile in under 10 minutes
                    </span>
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isRunMileTime"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isRunMileTime}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="black regular fz18 poppins d-block">
                      Push ups (at least 4 repetitions)
                    </span>
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isPushUps"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isPushUps}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="black regular fz18 poppins d-block">
                      Bench press (at least 50 percent of your body weight)
                    </span>
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isBenchPress"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isBenchPress}
                    />
                  </div>
                </div>

                <div className="mb-5">
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="black regular fz18 poppins d-block">
                      Sit ups (at least 14 repetitions in one minute)
                    </span>
                    <YesNoButtonComponent
                      formik={formik}
                      fieldName="isSitUps"
                      handleRadioChange={handleRadioChange}
                      value={formik.values.isSitUps}
                    />
                  </div>
                </div>

                <div className="mb-4">
                  <p className="grayopacity medium fz18 poppins mb-4 grayTitle">
                    If there are physical fitness tasks above you cannot meet,
                    but could do with an accommodation, please describe or
                    demonstrate how so.
                  </p>
                  <input
                    className="w-100"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="fitnessDescription"
                    value={formik.values.fitnessDescription}
                    style={{
                      height: "50px",
                      Border: "1px solid #C4C4C4",
                    }}
                  />
                </div>

                <div className="mb-4 mt-5 text-center">
                  <Button
                    className={`applicationBtn`}
                    onClick={formik.handleSubmit}
                  >
                    {" "}
                    Submit{" "}
                  </Button>
                </div>
              </div>
            </Form>
          </Container>
        </div>
      )}
    </Fragment>
  );
};

export default HeroSignupStep4;

