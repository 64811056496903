import React, { Fragment, useState, useEffect } from "react";
import { Container, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getHeroSignupProgress } from "../api/hero";
import Swal from "sweetalert2";

const VerificationStatus = ({ step, isDisable }) => {
  const handleAlert = () => {
    Swal.fire({
      icon: "warning",
      html: `
      We have detected flaws in your profile you can try to solve it by contacting us
        <br />
        <br />
        <a href="/hero-web/help">Please contact our support</a>
      `,
      showCloseButton: true,
      showConfirmButton: false,
    });
  };

  if (
    step.verificationStatus === "VERIFIED" ||
    step?.verificationStatus === "VERIFICATION"
  ) {
    return (
      <p className="mb-0 rightContent text-capitalize d-flex flex-row">
        {step?.verificationStatus.toLowerCase()}

        {step?.verificationStatus === "VERIFIED" && (
          <div className="ms-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <circle cx="10.5" cy="10.5" r="10.5" fill="#00CB45" />
              <path d="M5.5 11L9 15L15.5 6.5" stroke="white" stroke-width="2" />
            </svg>
          </div>
        )}
      </p>
    );
  } else if (step?.verificationStatus === "DENIED") {
    return (
      <p className="mb-0 rightContent text-capitalize" onClick={handleAlert}>
        {" "}
        <span
          style={{
            color: "red",
            cursor: "pointer",
          }}
        >
          Denied
        </span>{" "}
      </p>
    );
  } else if (
    step?.verificationStatus === "PENDING" ||
    step?.verificationStatus === "INVALID"
  ) {
    return (
      <p
        className="mb-0 rightContent text-capitalize"
        style={isDisable ? { pointerEvents: "none" } : {}}
      >
        {" "}
        <Link
          to={step?.link}
          className={`text-decoration-none fz20 medium ${
            step?.verificationStatus === "INVALID"
              ? "invalid"
              : !isDisable
              ? "pendingBtn"
              : ""
          }`}
        >
          {step?.verificationStatus === "PENDING" ? "Submit" : "Invalid"}
        </Link>{" "}
      </p>
    );
  } else {
    return <></>;
  }
};

const HeroSignupProgress = () => {
  const [activeStep, setActiveStep] = useState("");

  const [heroSignupProgress, setHeroSignupProgress] = useState({
    percentage: 0,
    steps: [
      {
        stepName: "drivingLicense",
        title: "Submit a photo of your ID or Driver’s License",
        link: "/hero-signup-step-1",
        verificationStatus: "PENDING",
      },
      {
        stepName: "securityLicense",
        title: "Submit a photo of your Security Officer License",
        link: "/hero-signup-step-2",
        verificationStatus: "PENDING",
      },
      {
        stepName: "profilePhoto",
        title: "Submit a  profile photo ",
        link: "/hero-signup-step-3",
        verificationStatus: "PENDING",
      },
      {
        stepName: "personalInfo",
        title: "Complete form of the Hero Security ",
        link: "/hero-signup-step-4",
        verificationStatus: "PENDING",
      },
      {
        stepName: "backgroundCheck",
        title: "Background check",
        link: "/hero-signup-step-5",
        verificationStatus: "PENDING",
      },
    ],
  });

  const handleStepClassName = (verificationStatus, index) => {
    let className =
      "step_container_row d-flex justify-content-between align-items-center ";
    if (verificationStatus === "PENDING" && index === 0) {
      return className + "active";
    } else if (verificationStatus === "VERIFIED") {
      return className + "done";
    } else if (verificationStatus === "VERIFICATION") {
      return className + "purpleText";
    } else if (
      heroSignupProgress.steps[index - 1]?.verificationStatus !== "PENDING"
    ) {
      return className + "active";
    } else {
      return className + "PENDING";
    }
  };

  useEffect(() => {
    getHeroSignupProgress().then((response) => {
      console.log(response);
      if (response.data.success) {
        const heroData = response.data.data.hero;
        let verifiedSteps = 0;
        let isActiveUpdated = false;
        let currentSteps = heroSignupProgress.steps;

        heroData?.avatarBaseUrl &&
          localStorage.setItem("S3BaseUrl", heroData?.avatarBaseUrl);
        //update driving License data
        currentSteps[0].verificationStatus =
          heroData["drivingLicense"]?.verificationStatus;
        heroData["drivingLicense"]?.verificationStatus == "VERIFIED" &&
          verifiedSteps++;
        if (
          !["VERIFIED", "VERIFICATION"].includes(
            heroData["drivingLicense"]?.verificationStatus
          ) &&
          !isActiveUpdated
        ) {
          isActiveUpdated = true;
          setActiveStep("drivingLicense");
        }
        //update security License data
        currentSteps[1].verificationStatus =
          heroData["securityLicense"]?.verificationStatus;
        heroData["securityLicense"]?.verificationStatus == "VERIFIED" &&
          verifiedSteps++;
        if (
          !["VERIFIED", "VERIFICATION"].includes(
            heroData["securityLicense"]?.verificationStatus
          ) &&
          !isActiveUpdated
        ) {
          isActiveUpdated = true;
          setActiveStep("securityLicense");
        }

        //update profile Photo data
        currentSteps[2].verificationStatus =
          heroData["profilePhoto"]?.verificationStatus;
        heroData["profilePhoto"]?.verificationStatus == "VERIFIED" &&
          verifiedSteps++;
        if (
          !["VERIFIED", "VERIFICATION"].includes(
            heroData["profilePhoto"]?.verificationStatus
          ) &&
          !isActiveUpdated
        ) {
          isActiveUpdated = true;
          setActiveStep("profilePhoto");
        }
        //update personal Info data
        currentSteps[3].verificationStatus =
          heroData["personalInfo"]?.verificationStatus;
        heroData["personalInfo"]?.verificationStatus == "VERIFIED" &&
          verifiedSteps++;
        if (
          !["VERIFIED", "VERIFICATION"].includes(
            heroData["personalInfo"]?.verificationStatus
          ) &&
          !isActiveUpdated
        ) {
          isActiveUpdated = true;
          setActiveStep("personalInfo");
        }
        //update background Check data
        currentSteps[4].verificationStatus =
          heroData["backgroundCheck"]?.verificationStatus;
        heroData["backgroundCheck"]?.verificationStatus == "VERIFIED" &&
          verifiedSteps++;
        if (
          !["VERIFIED", "VERIFICATION"].includes(
            heroData["backgroundCheck"]?.verificationStatus
          ) &&
          !isActiveUpdated
        ) {
          isActiveUpdated = true;
          setActiveStep("backgroundCheck");
        }

        //update data
        setHeroSignupProgress({
          percentage: verifiedSteps * 20,
          steps: currentSteps,
        });
      }
    });
  }, []);
  return (
    <div className="customer_signup sec_pd">
      {heroSignupProgress.percentage == 100 ? (
        <Container>
          <div className="customer_signup_process">
            <div className="processTitle black medium fz20 poppins text-center mB30">
              {" "}
              Verification process complete{" "}
            </div>
            <ProgressBar
              variant="success"
              now={heroSignupProgress?.percentage}
              striped={true}
            />
            <div className="mT50 mB50 text-center">
              <img src="assets/img/hero-avatar.svg" alt="hero" />
            </div>
            <p className="fz18 regular poppins black text-center mB45">
              Congratulations! You are now a Hero! <br></br>Download the app to
              get started
            </p>

            <div className="d-flex justify-content-center socialBtn mb-5">
              <a href="/#" className="me-2">
                <img src="assets/img/google-play.svg" alt="icon" />
              </a>

              <a href="/#">
                {" "}
                <img src="assets/img/app-store.svg" alt="icon" />{" "}
              </a>
            </div>
          </div>
        </Container>
      ) : (
        <Container>
          <div className="customer_signup_process">
            <div className="processTitle black medium fz20 poppins text-center mB20">
              {" "}
              Track your progress{" "}
            </div>
            <ProgressBar>
              <ProgressBar
                variant={
                  heroSignupProgress?.percentage >= 20 ? "fill" : "empty"
                }
                now={20}
              />
              <ProgressBar
                variant={
                  heroSignupProgress?.percentage >= 40 ? "fill" : "empty"
                }
                now={20}
              />
              <ProgressBar
                variant={
                  heroSignupProgress?.percentage >= 60 ? "fill" : "empty"
                }
                now={20}
              />
              <ProgressBar
                variant={
                  heroSignupProgress?.percentage >= 80 ? "fill" : "empty"
                }
                now={20}
              />
              <ProgressBar
                variant={
                  heroSignupProgress?.percentage == 100 ? "fill" : "empty"
                }
                now={20}
              />
            </ProgressBar>

            <div className="step_container mT20">
              {heroSignupProgress?.steps?.map((step, index) => {
                return (
                  <Fragment key={index.toString()}>
                    <div
                      className={handleStepClassName(
                        step?.verificationStatus,
                        index
                      )}
                    >
                      <p
                        className={`mb-0 ${
                          step?.verificationStatus === "DENIED"
                            ? "disabled-step"
                            : ""
                        }`}
                      >
                        {" "}
                        {step?.title}
                      </p>
                      <VerificationStatus
                        step={step}
                        isDisable={handleStepClassName(
                          step?.verificationStatus,
                          index
                        ).includes("PENDING")}
                      />
                    </div>
                    <hr></hr>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
};

export default HeroSignupProgress;

