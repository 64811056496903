import React from "react";
import { Col, Container, Image, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const GetStarted = () => {
  return (
    <div>
      <div className="innerHeader d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="#home" className="bold poppins black">
              What is Hero
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/what-hero">
                  Overview
                </Nav.Link>
                <Nav.Link as={Link} to="/how-it-works">
                  How It Works
                </Nav.Link>
                <Nav.Link as={Link} to="/fees">
                  Fees
                </Nav.Link>
                <Nav.Link as={Link} to="/getstarted" active>
                  Get Started
                </Nav.Link>
                <Nav.Link as={Link} to="/customer-future">
                  The future of Hero
                </Nav.Link>
                <Nav.Link as={Link} to="/customer-contact">
                  Contact us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <section className=" getstarted_sec1">
        <Container>
          <Row className="align-items-center">
            <Col md={6} sm={12}>
              <div className="sectionTitle mB40">
                <h2 className="black poppins fz48 bold mb-4">
                  How to register
                </h2>
                <p className="mb-0 fz20 regular poppins black lh33">
                  HERO is the convenient and simple way <br></br> to request
                  personal protection. Equally <br></br> simple is the sign-up
                  process. Follow the <br></br> steps below and you could be up
                  and <br></br>running in no time!
                </p>
                <Link
                  to="/customer-signup"
                  className="btn btn-outline btn-outline-dark mT30"
                >
                  Sign up now
                </Link>
              </div>
            </Col>

            <div className="imageIcon text-center">
              <Image src="assets/img/register1.png"></Image>
            </div>
          </Row>
          <hr className="color mT90"></hr>
        </Container>
      </section>

      <section className="getstarted sec_pd">
        <Container>
          <h4 class="mB70 fz40 bold poppins black getstarted_title">
            {" "}
            Get started{" "}
          </h4>
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/step1.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">1. Sign up online</p>
              <span className="poppins fz14 regular d-block lh25">
                Got an email address and a smartphone? Great, now follow the{" "}
                <br></br> process below via our online sign-up or via the app if
                you’ve already <br></br> downloaded it.
              </span>
              {/* <Link
                to="/customer-help"
                className="fz15 poppins bold mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/step2.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                2. Verify your phone
              </p>
              <span className="poppins fz14 regular d-block lh25">
                You will be prompted to enter your mobile number followed by a
                verification code that we will send you.
              </span>
              {/* <Link
                to="/customer-help"
                className="fz15 poppins bold mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/step3.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                3. Complete your personal details
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Our form will guide you through the information we need about
                you; title, name, email and password.
              </span>
              {/* <Link
                to="/customer-help"
                className="fz15 poppins bold mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/step4.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                4. Upload your profile photo
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Keep going, you’re nearly there! Next you will be prompted to
                upload a<br></br> profile picture of yourself. You can take one
                from within the app or<br></br>
                upload one you’ve already taken. Make sure it’s a clear current
                picture <br></br> of yourself to help your Hero identify you
                when you meet.
              </span>
              {/* <Link
                to="/customer-help"
                className="fz15 poppins bold mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/step5.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                5. Add your payment method
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Finally add a payment method manually or by photo capture and
                <br></br> you’re good to go.
              </span>
              {/* <Link
                to="/customer-help"
                className="fz15 poppins bold mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>
        </Container>
      </section>

      <section className="getKnow sec_pd">
        <Container>
          <Row className="align-items-center">
            <Col md={6} sm={12}>
              <div className="sectionTitle mB40">
                <h2 className="black poppins fz40 bold mb-4">Get to know us</h2>
                <p className="mb-0 fz20 regular poppins black lh33">
                  Everything you need to start using the<br></br> service can be
                  checked in this handy<br></br> quick reference guide.
                </p>
                <Link
                  href="/#"
                  className="black fz20 regular poppins mT30 text-decoration-none d-inline-block"
                >
                  Check our quick guide
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </Link>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="imageIcon">
                <Image src="assets/img/getknow.png" className="w-100"></Image>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sec_pd journeySupport">
        <Container>
          <div class="crimePicture_title fz40 bold poppins black mB70">
            Support at every step of your journey
          </div>
          <Row>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/get-help0.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Get support
                    </h4>
                  </div>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Support is always available for any service issues or
                    queries before, after or during a journey from directly
                    within the app. Also, during a journey our Heroes are on
                    hand to help you directly with any questions or just to
                    reassure any concerns you may have.
                  </p>
                  {/* <Link
                    to="/help"
                    className="black fz15 regular poppins mT30 text-decoration-none d-inline-block"
                  >
                    Get help
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link> */}
                </div>
              </div>
            </Col>
            {/* <hr className="d-block d-lg-none mt-4"></hr> */}
            <hr className="d-block d-lg-none mt-4 mb-0"></hr>

            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-4 mb-lg-0 mt-4 mt-lg-0">
                <div class="whyHero_Wors_cardcontent">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/mick.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Contact us
                    </h4>
                  </div>

                  <p class="mb-0 fz15 regular poppins lh22 black">
                    You can contact our support center and speak to one of our
                    friendly, knowledgeable Hero representatives who can be
                    reached 24/7.
                  </p>

                  {/* <Link
                    href="/#"
                    className="black fz15 regular poppins mT30 text-decoration-none d-inline-block"
                  >
                    Contact us
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link> */}
                </div>
              </div>
            </Col>
            <hr className="d-block d-lg-none mt-4"></hr>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/feel-safe.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">Feel safe</h4>
                  </div>

                  <p class="mb-0 fz15 regular poppins lh22 black">
                    The HERO app and website are full of features and content
                    that help you stay safe and confident while getting around
                    your city.
                  </p>

                  <Link
                    to="/customer-safety"
                    className="black fz15 regular poppins mT30 text-decoration-none d-inline-block"
                  >
                    Lean more about safety
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default GetStarted;
