// import logo from "./logo.svg";
import "./style.css";
import "./responsive.css";
import RoutesComponent from "./components/Routes";
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <ScrollToTop>
      <div className="App">
      <RoutesComponent />
    </div>

    </ScrollToTop>
    
  );
}

export default App;
