import React from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const WhatisHero = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="innerHeader d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="#home" className="bold poppins black">
              What is Hero
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="d-lg-block">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/hero-overview">
                  Overview
                </Nav.Link>
                <Nav.Link as={Link} to="#" active>
                  How to register
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-earning">
                  Earnings
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-how-it-works">
                  How it works
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-using-app">
                  Using the app
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-contact">
                  Contact us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <section className="safetyTechnology what_is sec_pd ">
        <Container>
          <Image
            className="d-block d-lg-none"
            src="assets/img/whatis-hero.png"
          ></Image>
          <Row className="align-items-center h-100">
            <Col sm={12} lg={6}>
              <div className="sectionTitle heroOv">
                <h2 className="white poppins fz48 bold mB30">
                  How to register to <br></br>become a Hero
                </h2>
                <p className="mb-0 fz20 regular poppins white lh30">
                  There are only a few simple steps to becoming a Hero. We’ll
                  need a few documents from you to prove that you’re capable and
                  then you’re ready <br></br>to protect the streets.
                </p>
                <Link
                  to="/hero-signup"
                  className="btn btn-outline btn-outline-light mT30 fz15"
                >
                  Sign up now
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="getstarted sec_pd mt-4 mt-lg-0">
        <Container>
          <h4 class="mB90 fz40 bold poppins black getstarted_title">
            {" "}
            Get started{" "}
          </h4>
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/step1.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 medium d-block"> 1. Sign up online </p>
              <span className="poppins fz16 regular d-block lh25">
                Got an email address and a smartphone? Great, now tell us{" "}
                <br></br>a bit more about yourself and we'll get you started.
              </span>

              <Link
                to="/hero-signup"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                sign up now
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </div>
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/upload-document.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                2. Upload your documents
              </p>
              <span className="poppins fz16 regular d-block lh25 mB10">
                Time to show us a few documents that we need to see:
              </span>
              <span className="poppins fz16 regular d-block lh25">
                <b>Drivers license </b>- A valid license to confirm your
                identity<br></br>
                <b>Security Guard license - </b>A valid license for the area you
                <br></br>
                want to operate in. If you don’t have one contact us to learn
                how to apply. <br></br>
                <b> Profile photo - </b>Ensure it is an unfiltered, passport
                style photo looking straight ahead
              </span>

              {/* <Link
                to="/help"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/step3.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                3. Complete a form to submit your personal <br></br>information
              </p>
              <span className="poppins fz16 regular d-block lh25">
                Our form will guide you through the information we need about
                <br></br>
                you, your skills and experience such as physical
                characteristics, <br></br>relevant training and physical
                fitness.
              </span>

              {/* <Link
                to="/help"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/bg-check.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                4. Complete a Background Check
              </p>
              <span className="poppins fz16 regular d-block lh25">
                We will need to perform a background check, so ensure all of
                your details are accurate and current. All information is held
                securely. This is also needed to process your payments.
              </span>
              {/* <Link
                to="/help"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="assets/img/active-account.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                5. Activate your account
              </p>
              <span className="poppins fz16 regular d-block lh25">
                Once you have received notification that your application is
                successful, download the app, sign in and complete your banking
                information, so you’re ready to start.
              </span>
              {/* <Link
                to="/help"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>
        </Container>
      </section>

      <section className="heroMission_Sec sec_pd rulesand_Code bgdarkGray">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="sectionTitle pR70">
                <h2 className="black poppins fz36 bold mb-3">
                  Rules and code <br></br>of conduct
                </h2>
                <p className="mb-0 fz16 regular poppins black lh25 ">
                  To be a Hero you must be willing to follow our standards of
                  professionalism. These are set to protect you and our
                  customers and help guide you to be your best Heroic self. Our
                  guide includes information on the app, ranks, dress code,
                  rules and important facts.
                </p>

                <p className="mb-0 mt-5 fz16 bold poppins black lh25 ">
                  Become a Hero and Download the Hero guide
                </p>
                <br></br>
              </div>
              {/* <Button
                onClick={() => {
                  navigate("what-hero");
                }}
                className="btn btn-outline btn-outline-dark mT30 d-none d-lg-block"
              >
                Become a Hero
              </Button> */}
              <Link
                href="#"
                className="black fz15 regular poppins mT30 d-inline-block d-lg-none"
              >
                Become a Hero
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </Col>
            <Col md={6} sm={12} className="d-none d-lg-block">
              <div className="imgWrap text-center">
                <Image src="assets/img/herocard.png"></Image>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="sec_pd helpingHand">
        <Container>
          <div class="helpingHand_title fz40 bold poppins black mB70">
            A helping hand for all our Heroes
          </div>
          <Row>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/get-help.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Get support
                    </h4>
                  </div>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Support is always available for any service issues or
                    queries before, after or during a journey from directly
                    within the app. Also, during a journey our Heroes are on
                    hand to help you directly with any questions or just to
                    reassure any concerns you may have.
                  </p>
                  {/* <Link
                    to="/help"
                    className="black fz15 bold poppins mT30 text-decoration-none d-inline-block"
                  >
                    Get help
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link> */}
                </div>
              </div>
            </Col>

            <hr className="d-block d-lg-none mt-4 mb-0"></hr>

            <Col sm={12} md={12} lg={4} className="">
              <div class="whyHero_Wors_card p-0 shadow-none mb-4 mb-lg-0 mt-4 mt-lg-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/mick.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Contact us
                    </h4>
                  </div>

                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Contact us You can reach us at our 24/7 support center or
                    see our on-site supervisor in person for a face-to-face
                    meeting.
                  </p>

                  {/* <Link
                    href="#"
                    className="black fz15 bold poppins mT30 text-decoration-none d-inline-block"
                  >
                    Contact us
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link> */}
                </div>
              </div>
            </Col>
            <hr className="d-block d-lg-none mt-4"></hr>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/protect-safely.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      {" "}
                      Protect safely
                    </h4>
                  </div>

                  <p class="mb-0 fz15 regular poppins lh22 black">
                    The HERO app and website are full of features and content
                    that help you stay safe and confident before, during and
                    after every assignment.
                  </p>

                  <Link
                    to="/customer-safety"
                    className="black fz15 bold poppins mT30 text-decoration-none d-inline-block"
                  >
                    Read more about safety
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};
export default WhatisHero;
