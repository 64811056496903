import React from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    return (
        <div>
            <div class="responsive-map">
                <Form className="loginForm text-center">

                    <Image className="heroshield" src="assets/img/hero-shield.svg"></Image>
                    <div className="form-group">
                        <Form.Control
                            type="password"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                            placeholder="username"
                        />
                    </div>
                    <div className="form-group">
                        <Form.Control
                            type="password"
                            id="inputPassword5"
                            aria-describedby="passwordHelpBlock"
                            placeholder="password"
                        />
                    </div>
                    <div className="form-group text-center mT20">
                        <Button
                            onClick={() => {
                                navigate("company-home");
                            }}
                            className="btn btn-outline btn-outline-black px-5">
                            Log in
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default Login;
