import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const HeroSafety = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section className="safetyTechnology sec_pd heroSafety">
        <Container>
          <Row className="align-items-center h-100">
            <Col sm={12} lg={5}>
              <div className="sectionTitle">
                <h2 className="white poppins fz48 bold mB40">
                  Safety features<br></br> to help you on the streets
                </h2>
                <p className="mb-0 fz20 regular poppins white lh33">
                  You use your protection skills, we provide the opportunity and
                  support. Use the technology of HERO to keep yourself safe
                  while protecting those around you.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="whyHero_Wors sec_pd safety_sec2">
        <Container>
          <div className="whyHero_Wors_title fz40 bold poppins black mB70">
            Piece of mind for your assignment
          </div>
          <Row>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/emergency.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    Emergency assistance button
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    You can use the in-app emergency button to call the
                    authorities and get help if you need it. The app displays
                    your location and trip details, so you can share them with
                    emergency services quickly.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/support3.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    24/7 incident support
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    The Hero support team are trained in incident response and
                    are available around the clock..
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/trained-ic.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    Follow your assignment
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    Friends and family can follow your route and will know as
                    soon as you arrive. Coming soon
                  </p>

                  <p className="blue fz15 bold poppins mT30">coming soon</p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/rating-ic.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    2-way ratings
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    Your feedback matters. Low-rated trips are logged, and
                    incidents reviewed. Our operatives may be removed if they do
                    not perform to our high standards. We take our
                    responsibilities to protect the HERO community seriously.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/phone-ic.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    Phone anonymisation
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    If you need to contact your Hero through the app, your phone
                    number can stay private.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/gps-tracking.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    GPS tracking
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    All Hero assignment journeys are tracked from start to
                    finish, so there’s a record of your trip if something
                    happens. We also monitor safety distance between Hero and
                    customer.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <hr className="color"></hr>
        </Container>
      </section>

      <section className="howhero_help sec_pd pt-0">
        <Container>
          <Row className="align-items-center h-100">
            <Col sm={12} md={12} lg={6}>
              <figure>
                <Image src="assets/img/heroneed.png"></Image>
              </figure>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <div className="whyHero_Wors_card p-0 shadow-none mb-0">
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz24 medium poppins  black">
                    Even Heros need protection
                  </h4>
                  <p className="mb-0 fz16 regular poppins lh25 black">
                    HERO will be partnering with leading<br></br> insurance
                    providers to help protect every<br></br> assignment. Coming
                    soon
                  </p>

                  <p className="blue fz15 bold poppins mT30">coming soon</p>
                </div>
              </div>
            </Col>

            <div className="mt-5"></div>
            <Col sm={12} md={12} lg={6} className="order2">
              <div className="whyHero_Wors_card p-0 shadow-none pR40 mb-0">
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz24 medium poppins  black">
                    Knowledge is power
                  </h4>
                  <p className="mb-0 fz16 regular poppins lh25 black">
                    Discover some of our safety articles to help broaden{" "}
                    <br></br> your knowledge and practical tips around the
                    issues of <br></br> staying safe.
                  </p>
                  <div>
                    <Button
                      onClick={() => {
                        navigate("/safety-resource");
                      }}
                      className="bg-transparent border-0 shadow-0 fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block linkbtn p-0"
                    >
                      Read more about safety
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                      </svg>
                    </Button>
                  </div>

                  {/* <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Read more about safety
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link> */}
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={6} className="order1">
              <figure>
                <Image src="assets/img/map.png"></Image>
              </figure>
            </Col>
            <div className="mt-5"></div>

            <Col sm={12} md={12} lg={6}>
              <figure>
                <Image src="assets/img/user.png"></Image>
              </figure>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <div className="whyHero_Wors_card p-0 shadow-none mb-0">
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz24 medium poppins  black">
                    Keeping the community strong
                  </h4>
                  <p className="mb-0 fz16 regular poppins lh25 black">
                    HERO’s guidelines and rules of conduct help <br></br>{" "}
                    everyone enjoy stress-free assignments. <br></br>
                    Anyone who doesn’t follow the guidelines <br></br>may be at
                    risk of being removed from the <br></br>platform for the
                    safety of the entire Hero  <br></br>community.<br></br>
                    <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                      Read our Hero Beginners Guide
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                      </svg>
                    </Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default HeroSafety;
