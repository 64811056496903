import React from "react";
import { Col, Container, Image, Nav, Navbar, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};
const HeroEarning = () => {
  return (
    <div>
      <div className="innerHeader d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="#home" className="bold poppins black">
              What is Hero
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className=" d-lg-block">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/hero-overview">
                  Overview
                </Nav.Link>
                <Nav.Link as={Link} to="/whatis-hero">
                  How to register
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-earning" active>
                  Earnings
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-how-it-works">
                  How it works
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-using-app">
                  Using the app
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-contact">
                  Contact us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <section className="sec_pd earning_banner_mobile d-block d-lg-none">
        <Container>
          <h4 className="black fz36 bold poppins mB30">
            How much can you earn with Hero?
          </h4>
          <Image src="assets/img/coin.png"></Image>
          <p className="mb-0 fz14 regular poppins black lh22">
            HERO is an on-demand app. That means there are no restrictions on
            when and how much you are capable of earning. Ideal whether you’re
            using it for part-time work or it’s your sole source of income.
          </p>
          <Link href="#" className="btn-dark mT30">
            Learn More
          </Link>
        </Container>
      </section>
      <section className="sec_pd hero_overview_banner d-none d-lg-block earningbanner">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col md={12} sm={12} lg={6}>
              <div className="imgWrap text-center">
                <Image src="assets/img/earningbanner.png"></Image>
              </div>
            </Col>
            <Col md={12} sm={12} lg={6} className="order2">
              <div className="sectionTitle pR50">
                <h2 className="black poppins fz48 bold mB40">
                  How much can <br></br> you earn?
                </h2>
                <p className="mb-0 fz20 regular poppins black lh30">
                  HERO is an on-demand app. That means there are no restrictions
                  on when and how much you are capable of earning. Ideal whether
                  you’re using it for part-time work or it’s your sole source of
                  income.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="earning_calculation sec_pd bgdarkGray">
        <Container>
          <div class="earning_calculation_title fz40 bold poppins black mB60">
            How earnings are calculated
          </div>
          <Carousel responsive={responsive}>
            <div className="item_data black">
              <Image src="assets/img/note.svg"></Image>
              <h4 className="mT20 mB20 bold fz20 poppins">
                Tiered level payments
              </h4>
              <p className="mb-0 fz15 regular poppins lh20">
                To begin there will be a one flat level payment rate, however as
                HERO grows we will introduce three tiers of service level
                offering to maximise earnings. This will depend on your level of
                ability from regular people with the right mindset and physical
                fitness to martial artists and security professionals to
                ex-police, military, bodyguards and advanced security forces.
              </p>
            </div>
            <div className="item_data black">
              <Image src="assets/img/payments.svg"></Image>
              <h4 className="mT20 mB20 bold fz20 poppins">Surge payments</h4>
              <p className="mb-0 fz15 regular poppins lh20">
                Whenever there is a high demand for service in a particular area
                there may be an extra earning potential through higher base
                rates being charged.
              </p>
              <div>
                <Link className="mb-0 fz15 bold poppins blue text-decoration-none mT20 d-inline-block">
                  Coming soon.
                </Link>
              </div>
            </div>
            <div className="item_data black">
              <Image className="wh" src="assets/img/get-help5.svg"></Image>
              <h4 className="mT20 mB20 bold fz20 poppins">
                Minimum assignment payments
              </h4>
              <p className="mb-0 fz15 regular poppins lh20">
                The minimum payment per assignment for 15-minute service is
                $9.00 + tip
              </p>
            </div>
          </Carousel>
        </Container>
      </section>

      <section className="personal_security earnwith_app">
        <Container>
          <div class="earnwith_app_title fz40 bold poppins black mB60">
            Earn with the app
          </div>
          <Row className="align-items-center">
            <Col sm={12} lg={6}>
              <div class="imgWrap">
                <Image
                  src="assets/img/earningone1.png"
                  className="w-100"
                ></Image>
              </div>
            </Col>
            <Col sm={12} lg={6}>
              <div className="contentWrap p-5">
                <h4 className="black fz36 medium poppins mB30">
                  Get paid fast
                </h4>
                <p className="lh25 fz16 regular poppins">
                  You can start earning as soon as you’re approved. Equally
                  quick is our payment processing which means you automatically
                  get your earnings weekly. You can also make manual withdrawals
                </p>
              </div>
            </Col>
            <div class="mt-5"></div>
            <Col sm={12} lg={6}>
              <div class="imgWrap">
                <Image
                  src="assets/img/earningtwo.png"
                  className="w-100"
                ></Image>
              </div>
            </Col>
            <Col>
              <div className="contentWrap p-5">
                <h4 className="black fz36 medium poppins mB30">
                  Getting tipped for your service
                </h4>
                <p className="lh25 fz16 regular poppins">
                  After each assignment, your client will have the opportunity
                  to leave you a tip for providing a great service. Every tip
                  will be passed onto our Heroes, 100% in full.
                </p>
                <div>
                  <Link className="fz16 bold poppins mt-3 text-decoration-none black">
                    Learn more
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="20"
                      height="20"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link>
                </div>
              </div>
            </Col>
            <div class="mt-5"></div>
            <Col sm={12} lg={6} className="order2">
              <div class="imgWrap">
                <Image
                  src="assets/img/earningthree.png"
                  className="w-100"
                ></Image>
              </div>
            </Col>
            <Col>
              <div className="contentWrap p-5">
                <h4 className="black fz24 medium poppins mB30">
                  Track trends with the app
                </h4>
                <p className="lh25 fz16 regular poppins">
                  See stats and areas of high demand on your map so that you can
                  anticipate locations that will provide a higher density of
                  clients and which kind of assignments allow you to maximize
                  your earnings.
                </p>

                <div>
                  <Link className="blue poppins mt-3 fz16 bold text-decoration-none d-inline-block">
                    Feature coming soon.
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="how_they_work sec_pd">
        <Container>
          <Row>
            <Col sm={12} lg={8}>
              <div className="sectionTitle ">
                <h2 className="black poppins fz40 bold mB40">
                  Promotions and how they work
                </h2>
                <p className="mb-0 fz20 regular poppins black lh30">
                  In-app promotions based on where the HERO app expects<br></br>
                  the most trip requests in your area help you plan ahead andbr
                  <br></br> set goals to maximize earnings. Not all promotions
                  are<br></br> available to all operatives.
                </p>
              </div>
            </Col>
            <Image
              className="proImage"
              src="assets/img/howthe-work.svg"
            ></Image>
          </Row>
          <Row className="mt-4">
            <Col sm={12} md={12} lg={4}></Col>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-4 mb-lg-0 mt-4 mt-lg-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/number.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Reach a set number of <br></br>assignments
                    </h4>
                  </div>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Make extra money if you complete a set number of assignments
                    in a certain amount of time, when the offer is available.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/busy-times.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Providing protection during <br></br>busy times
                    </h4>
                  </div>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Get paid extra for assignments in certain areas at busy
                    times.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="workerProtection sec_pd">
        <Container>
          <Row>
            <Col sm={12} lg={6}>
              <div className="sectionTitle white">
                <h2 className="poppins fz40 bold mB40">
                  Your worker protections
                </h2>
                <p className="mb-0 fz20 regular poppins lh30">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
                <Link className="signUp_btn d-inline-block d-lg-none text-decoration-none mt-4">
                  Sign up with Hero
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
    </div>
  );
};

export default HeroEarning;

