import React, { useEffect, useState } from "react";

import { Col, Container, Image, Row, Nav, Navbar } from "react-bootstrap";
import { Link, Outlet, useNavigate } from "react-router-dom";
import CarouselComponent from "../components/CarouselComponent";
import { getNews } from "../api/News";
import { getFormattedDate } from "../utils";

const NewsRoom = () => {
  const [newsData, setNewsData] = useState([]);
  const [baseURL, setBaseURL] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    handleapiData();
  }, []);

  const handleapiData = () => {
    getNews({
      itemNumber: 5,
    })
      .then((response) => {
        setNewsData(response.data.data.newsList);
        setBaseURL(response.data.data.s3Base);
        console.log("response", response);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleNavigate = (url) => {
    navigate(url);
  };

  return (
    <>
      <div className="companyNews_one">
        <div className="innerbanner">
          <div className="innerHeader d-none d-lg-block">
            <Navbar expand="lg">
              <Container fluid>
                <Navbar.Brand
                  as={Link}
                  to="/customer-future"
                  className="bold poppins white"
                >
                  Company
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="m-auto">
                    <Nav.Link as={Link} to="/what-hero">
                      About us
                    </Nav.Link>
                    {/* <Nav.Link as={Link} to="/how-it-works">
                      Our Team
                    </Nav.Link> */}
                    <Nav.Link as={Link} to="/newsroom">
                      News
                    </Nav.Link>
                    <Nav.Link as={Link} to="/blog">
                      Blog
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </div>
          <Container>
            <h1 className="white fz40 bold poppins"> Hero News </h1>
          </Container>
        </div>
        <div className="sliderview_sec_outer">
          <Container>
            <div className="sliderview_sec">
              <CarouselComponent newsData={newsData} baseURL={baseURL} />
            </div>
          </Container>
        </div>
        <div className="recentNews sec_pd">
          <Container>
            <div className="row">
              <Col sm={12} md={12} lg={4} className="recentNews_title">
                <h4 className="fz36 medium poppins black"> Recent News </h4>
                <Link
                  className="fz20 medium black text-decoration-none"
                  to="/newsroom/news"
                >
                  See all
                  <svg
                    class="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
                  </svg>
                </Link>
              </Col>

              <Col sm={12} md={12} lg={8} className="recentNews_content">
                {newsData?.length > 0 &&
                  newsData.map((newsItem, index) => (
                    <div
                      key={index}
                      className="recentNews_content_item d-flex align-items-center justify-content-between"
                    >
                      <span className="fz20 regular poppins black">
                        <Link
                          to={
                            newsItem?.slug
                              ? `/newsroom/news/${newsItem?.slug}`
                              : ""
                          }
                          className="black text-decoration-none"
                        >
                          {newsItem.title}
                        </Link>
                      </span>

                      <span className="fz16 medium poppins black">
                        {getFormattedDate(newsItem.created)}{" "}
                      </span>
                    </div>
                  ))}
              </Col>
              <Col sm={12} className="d-block d-lg-none mt-4">
                <Link className="fz16 medium black text-decoration-none">
                  {" "}
                  See all
                  <svg
                    class="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"></path>
                  </svg>
                </Link>
              </Col>
            </div>
          </Container>
        </div>

        <div className="cm_info">
          <Container>
            <Row>
              <Col sm={12} md={12} lg={6} className="mb-md-4 mb-lg-0">
                <div
                  className="downloadapp_sec_box_inner"
                  onClick={() => handleNavigate("/company-team")}
                >
                  <figure className="p-0 mb-0"> </figure>
                  <div className="content w-50">
                    <p className="mb fz24 medium poppins black text-left">
                      Leadership
                    </p>
                    <span>The team that makes Hero work</span>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
              </Col>

              <Col sm={12} md={12} lg={6}>
                <div
                  className="downloadapp_sec_box_inner"
                  onClick={() => handleNavigate("/company-founder")}
                >
                  <figure className="p-0 mb-0"> </figure>
                  <div className="content w-50">
                    <p className="mb fz24 medium poppins black text-left">
                      Company info
                    </p>
                    <span>Find out about our Hero's vision</span>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default NewsRoom;

