import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Nav,
  Navbar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

const HeroOverview = () => {
  const [key, setKey] = useState("heros");
  return (
    <div>
      <div className="innerHeader d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="#home" className="bold poppins black">
              What is Hero
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="d-lg-block">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/hero-overview" active>
                  Overview
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-how-it-works">
                  How it works
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-earning">
                  Earnings
                </Nav.Link>
                <Nav.Link as={Link} to="/whatis-hero">
                  Register
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-using-app">
                  Using the app
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-contact">
                  Contact us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <section className="become_hero_mobile d-block d-lg-none sec_pd">
        <Container>
          <div className="sectionTitle_mobile mB30">
            <h2 className="black poppins fz36 bold mB30">
              Flexible security opportunities to protect our streets
            </h2>
            <p className="mb-0 fz14 regular poppins black lh22">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
          <p className="fz24 medium poppins black">Become a Hero</p>
          <Form>
            <Form.Group className="mB10" controlId="formBasicEmail">
              <Form.Control type="text" placeholder="Enter email" />
            </Form.Group>
            <Form.Group className="mB10" controlId="formBasicEmail">
              <Form.Control type="text" placeholder="last Name" />
            </Form.Group>
            <Form.Group className="mB10" controlId="formBasicEmail">
              <Form.Control type="email" placeholder="Email" />
            </Form.Group>
            <Form.Group className="mB10" controlId="formBasicEmail">
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>
            <Form.Group className="mB10" controlId="formBasicEmail">
              <Form.Control type="number" placeholder="Phone Number" />
            </Form.Group>
            <Form.Group className="mB10" controlId="formBasicEmail">
              <Form.Control
                type="number"
                placeholder="Phone Number"
                value="New York, US"
              />
            </Form.Group>
            <Form.Group className="mB10" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Invitation code (optional)"
              />
            </Form.Group>

            <div className="fz9 regular black poppins mT20">
              <p className="">
                By proceeding, I agree to Hero's{" "}
                <Link className="text-decoration-none"> Terms of Use </Link>and
                acknowledge that I have read the{" "}
                <Link className="text-decoration-none">Privacy Policy.</Link>
              </p>
              <p>
                I also agree that Hero or its representatives may contact me by
                email, phone or SMS (including by automated means) at the email
                address or number I provide, including for marketing purposes.
              </p>
            </div>
            <div className="btnRow d-flex align-items-center">
              <Button variant="dark" className="semibold fz16 poppins me-3">
                Sign Up
              </Button>
              <Link className="regular text-decoration-none fz9 poppins black">
                Already have an account? Sign in
              </Link>
            </div>
          </Form>
        </Container>
      </section>
      <section className="sec_pd hero_overview_banner">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col md={12} sm={12} lg={6} className="order2">
              <div className="sectionTitle pR50 heroOv">
                <h2 className="black poppins fz48 bold mB40">
                  What is the Hero <br></br> protection service?
                </h2>
                <p className="mb-0 fz20 regular poppins black lh30">
                  Our app enabled platform uses on-demand, secure mapping and
                  tracking technology to link customers with Hero operatives to
                  enable a safer journey for all, while walking around our city
                  streets.
                </p>
                <Link
                  to="/whatis-hero"
                  className="btn btn-outline btn-outline-dark mT30"
                >
                  How it works
                </Link>
              </div>
            </Col>
            <Col md={12} sm={12} lg={6}>
              <div className="imgWrap text-center">
                <Image src="assets/img/hero-overview.png"></Image>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="hero_works sec_pd">
        <Container>
          <div className="hero_works_title fz40 bold poppins white mB30">
            Why Hero works
          </div>
          <Tabs
            id="uncontrolled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
            transition={false}
          >
            <Tab eventKey="heros" title="Heros">
              <div className="tabContent">
              <Carousel responsive={responsive}>
                <div className="item_data white">
                <Image src="assets/img/doller.svg"></Image>
                <h4 className="mT20 mB20 bold fz20 poppins">
                    {" "}
                    Get your earnings fast
                </h4>
                <p className="mb-0 fz15 regular poppins lh20">
                    {" "}
                    When you earn using HERO, your earnings automatically get
                    transferred to your bank account every week. Plus, you can
                    withdraw funds manually up to twice a day.
                </p>
                </div>
                <div className="item_data white">
                <Image src="assets/img/hour.svg"></Image>
                <h4 className="mT20 mB20 bold fz20 poppins">
                    {" "}
                    Get your earnings fast
                </h4>
                <p className="mb-0 fz15 regular poppins lh20">
                    {" "}
                    When you earn using HERO, your earnings automatically get
                    transferred to your bank account every week. Plus, you can
                    withdraw funds manually up to twice a day.
                </p>
                </div>
                <div className="item_data white">
                <Image src="assets/img/time.svg"></Image>
                <h4 className="mT20 mB20 bold fz20 poppins">
                    {" "}
                    Get your earnings fast
                </h4>
                <p className="mb-0 fz15 regular poppins lh20">
                    {" "}
                    When you earn using HERO, your earnings automatically get
                    transferred to your bank account every week. Plus, you can
                    withdraw funds manually up to twice a day.
                </p>
                </div>
            </Carousel>
              </div>
            </Tab>

            <Tab eventKey="customers" title="Customers">
              <div className="tabContent">
              <Carousel responsive={responsive}>
                  <div className="item_data white">
                    <Image src="assets/img/safety0.svg"></Image>
                    <h4 className="mT20 mB20 bold fz20 poppins">
                      {" "}
                      Safety with every step
                    </h4>
                    <p className="mb-0 fz15 regular poppins lh20">
                      {" "}
                      Your dedication to protection allows our customers to take
                      back control as they walk the streets and that your
                      presence can deter crime. They can feel safe in the
                      knowledge you will be there to protect their every move.
                    </p>
                  </div>
                  <div className="item_data white">
                    <Image src="assets/img/user01.svg"></Image>
                    <h4 className="mT20 mB20 bold fz20 poppins">
                      {" "}
                      They’re never alone with a Hero just around the corner
                    </h4>
                    <p className="mb-0 fz15 regular poppins lh20">
                      {" "}
                      Our app connects customers to you with the convenience of
                      knowing wherever they find themselves or whatever journey
                      they wish to make, you will be ready and waiting to come
                      to their assistance at the tap of a button.
                    </p>
                  </div>
                  <div className="item_data white">
                    <Image
                      className="wh"
                      src="assets/img/get-help0.svg"
                    ></Image>
                    <h4 className="mT20 mB20 bold fz20 poppins">
                      {" "}
                      Get support at every step
                    </h4>
                    <p className="mb-0 fz15 regular poppins lh20">
                      {" "}
                      With 24/7 support, customers can rest assured that any
                      queries can be answered straight away. Our team is on hand
                      to keep their mind at rest while you keep them safe.
                    </p>
                  </div>
                </Carousel>
              </div>
            </Tab>
          </Tabs>
        </Container>
      </section> */}

      <section className="hero_respected d-block d-lg-flex align-items-center sec_pd">
        <div className="hero_respected_title fz20 bold poppins white mB20 d-block d-lg-none">
          Be respected. Become someone’s Hero.
        </div>
        <Image
          className="d-block d-lg-none"
          src="assets/img/be-respected.png"
        ></Image>
        <Container>
          <div className="hero_respected_title text-center fz40 bold poppins white mB80 d-none d-lg-block">
            Be respected. Become someone’s Hero.
          </div>
          <Row>
            <Col sm={12} md={6} lg={4}>
              <div className="hero_respected_card poppins fz16 regular lh25 black d-flex align-items-center text-lg-center">
                <span>
                  <b>Heroes are </b>vetted, dedicated and trained individuals,
                  dedicated to the safety of others.
                </span>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <div className="hero_respected_card poppins fz16 regular lh25 black d-flex align-items-center text-lg-center">
                <span>
                  <b>Heroes are </b> understanding and compassionate to the
                  needs of the protected person.
                </span>
              </div>
            </Col>
            <Col sm={12} md={6} lg={4}>
              <div className="hero_respected_card poppins fz16 regular lh25 black d-flex align-items-center  text-lg-center">
                <span>
                  <b>Heroes are </b> consummate professionals who are courteous
                  not just to the client but to everyone.
                </span>
              </div>
            </Col>
          </Row>
          <div className="hero_respected_bottom_data mT80 white text-lg-center">
            <p className="text-lg-center fz32 bold poppins white lh36 mB45">
              A Hero dares to make a difference.<br></br> Have you got what it
              takes?
            </p>
            <Link
              to="/whatis-hero"
              className="fz16 poppins bold d-inline-block text-decoration-none white d-none d-lg-block"
            >
              {" "}
              Register now
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
            </Link>
          </div>
        </Container>
      </section>

      {/* <section className="heroMission_Sec sec_pd herooverview_mission">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12} className="order2">
              <div className="sectionTitle pR70">
                <h2 className="black poppins fz36 bold mb-3">
                  The Hero Mission
                </h2>
                <p className="mb-0 fz15 regular poppins black lh25">
                  Today we live in a world where feeling safe has become
                  increasingly difficult. But what if we could feel safe and
                  protected on our streets? What if we could always have a Hero
                  around us when we need one? Our lives would no longer be in
                  danger.
                </p>
                <br></br>
                <p className="mb-0 fz15 regular poppins black lh25">
                  With Hero, wherever you go, we go with you paving a safer way
                  for you and your family.
                </p>{" "}
                <br></br>
                <p className="mb-0 fz15 regular poppins black lh25">
                  At any time, Hero’s are available for you with one mission, to
                  protect you. From the moment you join your Hero, we take care
                  of your safety so the only thing you need to worry about, is
                  enjoying life.
                </p>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="imgWrap text-center">
                <Image src="assets/img/hero-guard.png"></Image>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      <section className="downloadapp_sec  bglight">
        <Container>
          <div className="sectionTitle text-center">
            <h2 className="black poppins fz36 bold mb-3">
              This is just the beginning
            </h2>
          </div>

          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pR65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="./assets/img/logo.svg"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br>the Hero app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Everyone deserves one
                </p>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pL65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="assets/img/logoblue.png"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br>I am a Hero app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Become a Hero
                </p>
              </div>
            </Col>
          </Row>

          <p className="mb-0 fz20 regular poppins black text-center ">
            Explore the beta version of our app, before <br></br>our full
            service launch - coming soon.
          </p>
        </Container>
      </section>
    </div>
  );
};

export default HeroOverview;

