import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Container, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { profile } from "../api/user";

const CustomerSignupProgress = () => {

    const [activeStep, setActiveStep] = useState("");

    const [customerSignupProgress, setCustomerSignupProgress] = useState({
        percentage: 0,
        steps: [
            { stepName: "personalDetails", title: "Submit personal details", link: "/customer-signup-step-1", verificationStatus: "Done" },
            { stepName: "profilePhoto", title: "Submit a profile photo (optional) ", link: "/customer-signup-step-2", verificationStatus: "Submit" },
            { stepName: "paymentMethod", title: "Add payment method", link: "/customer-signup-step-3", verificationStatus: "Add" },
        ]
    });

    useEffect(() => {
        profile().then(response => {
            if (response.data.success) {
                const customerData = response.data.data?.user;
                let verifiedSteps = 1;
                let isActiveUpdated = false;
                let currentSteps = customerSignupProgress.steps;

                //update profile data
                if (customerData?.avatar) {
                    verifiedSteps++;
                    currentSteps[1]['verificationStatus'] = 'Done';
                }

                if (!customerData?.avatar && !isActiveUpdated) {
                    isActiveUpdated = true;
                    setActiveStep('profilePhoto');
                }

                //update payment data
                if (customerData?.card && customerData?.card.length > 0) {
                    verifiedSteps++;
                    currentSteps[2]['verificationStatus'] = 'Done';
                }

                if (!customerData?.card && !isActiveUpdated) {
                    isActiveUpdated = true;
                    setActiveStep('paymentMethod');
                }
                //update data
                setCustomerSignupProgress({
                    percentage: verifiedSteps * 33.34,
                    steps: currentSteps
                })
            }
        });

    }, [])
    return (
        <div className="customer_signup sec_pd">

            <Container>
                <div className="customer_signup_process">
                    <div className="processTitle black medium fz20 poppins text-center mB20"> Track your progress </div>
                    <ProgressBar>
                        <ProgressBar variant={customerSignupProgress?.percentage >= 33.33 ? 'fill' : 'empty'} now={33.33} />
                        <ProgressBar variant={customerSignupProgress?.percentage >= 66.66 ? 'fill' : 'empty'} now={33.33} />
                        <ProgressBar variant={customerSignupProgress?.percentage >= 100 ? 'fill' : 'empty'} now={33.34} />
                    </ProgressBar>

                    <div className="step_container mT20">
                        {
                            customerSignupProgress?.steps?.map((step, index) => {
                                return (
                                    <Fragment key={index.toString()}>
                                        <div className={`step_container_row ${activeStep === step?.stepName ? 'active' : (step?.verificationStatus == "Done" ? 'done' : '')} d-flex justify-content-between align-items-center`}>
                                            <p className="mb-0"> {step?.title}</p>
                                            <div>
                                                {
                                                    (step?.verificationStatus == "Done") && <p className="mb-0 rightContent text-capitalize d-flex flex-row">
                                                        Done
                                                    </p>
                                                }
                                                {
                                                    (step?.verificationStatus == "Submit" || step?.verificationStatus == "Add") && <p className="mb-0 rightContent text-capitalize"> <Link to={step?.link} className="text-decoration-none fz20 medium">{step?.verificationStatus.toLowerCase()}</Link> </p>
                                                }
                                                {/* {
                                                    (step?.verificationStatus == "Done") && <p className="mb-0 rightContent text-capitalize"> <Link to={step?.link} className="text-decoration-none fz20 medium">Edit</Link> </p>
                                                } */}
                                            </div>
                                        </div>
                                        <hr></hr>
                                    </Fragment>
                                )
                            })
                        }

                        <div className={`step_container_row ${customerSignupProgress?.percentage === 100 ? 'active' : ''} d-flex justify-content-between align-items-center`}>
                            <p className="mb-0"> Download app</p>
                            {
                                <p className="mb-0 rightContent text-capitalize d-flex flex-row">
                                    <Link to={'/customer-signup-step-4'} className="text-decoration-none fz20 medium">Download</Link>
                                </p>
                            }
                        </div>

                    </div>
                </div>
            </Container>

        </div>
    );
};

export default CustomerSignupProgress;
