import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import { getPage } from "../api/Page";


const StaticPage = () => {
    let location = useLocation();
    const [pageDetail, setPageDetail] = useState();

    const handleApiData = async (slug) => {
        try {
          const response = await getPage({slug});
          const pageData = response.data.data;
          setPageDetail(pageData);
        } catch (err) {
          console.error("Error fetching data:", err);
        }
      }

    useEffect(() => {
        handleApiData(location.pathname.replace("/", ""));
    }, [location.pathname]);

  return (
    <div>
      <div className="blogDetail_sec sec_pd">
        <Container>
          <div className="blogDetail_sec_content d-lg-flex align-items-start">
            <div className="blogDetail_sec_contentinner order1">
              {!!pageDetail?.title && parse(pageDetail?.description)}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default StaticPage;