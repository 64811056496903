import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { PageVisited } from '../utils';

const ScrollToTop = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
        // FB pixel
        if (location?.pathname !== '/') {
            PageVisited(location?.pathname);
        }
        
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [location?.pathname]);

    return children || null;
};

export default ScrollToTop;