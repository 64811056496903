import React, { Fragment } from "react"

const YesNoButtonComponent = ({ formik, fieldName, handleRadioChange, value }) => {
    return (
        <Fragment>
            <div className="statusItem">
                <div className="ctradio-button">
                    <input type="radio" id={`${fieldName}Yes`} name={fieldName} onChange={handleRadioChange} onBlur={formik.handleBlur} value="true" checked={value === true} />
                    <label htmlFor={`${fieldName}Yes`}>Yes</label>

                    <input type="radio" id={`${fieldName}No`} name={fieldName} onChange={handleRadioChange} onBlur={formik.handleBlur} value="false" checked={value === false} />
                    <label htmlFor={`${fieldName}No`}>No</label>
                </div>
                {formik.touched[fieldName] && formik.errors[fieldName] && (
                    <div className="text-danger field-error fz12 text-end">{formik.errors[fieldName]}</div>
                )}
            </div>
        </Fragment>
    )
}

export default YesNoButtonComponent;