import base from "./base";

export const getBlogs = (payload = {}) => {
  const { page = 1, perPage = 5 } = payload;

  return base.get(`/utils/blog?page=${page}&perPage=${perPage}`);
};

export const getBlogDetail = (payload = {}) => {
  const { slug } = payload;
  return base.get(`/utils/blog/${slug}`);
};

//constantinfo.net:1225/api/utils/blog/how-my-internship-expand-my-horizon

