import React, { useState } from "react";
import { Button, Carousel, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { getFormattedDate, truncateHTMLString } from "../utils";

const CarouselComponent = ({ newsData = [], baseURL }) => {
  return (
    <Carousel fade className="w-100 customCarousel">
      {newsData?.length > 0 &&
        newsData.map((news) => (
          <Carousel.Item
            key={news._id}
            className="d-flex justify-content-center"
          >
            <div className="sliderview_sec_img">
              <Image
                className="blog-img"
                src={`${baseURL}${news.banner}`}
              ></Image>
            </div>
            <Carousel.Caption className="p-0">
              <Link
                to={news?.slug ? `/newsroom/news/${news?.slug}` : ""}
                className="black text-decoration-none"
              >
                <div className="sliderview_sec_content h-100">
                  <span className="fz16 medium poppins black">
                    {getFormattedDate(news.created)}{" "}
                  </span>
                  <span className="fz24 medium poppins black d-block mb-5 titletext">
                    {news.title}
                  </span>
                  <p className="fz18 regular poppins black text-start pratext">
                    {parse(truncateHTMLString(news.description))}
                  </p>
                </div>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
    </Carousel>
  );
};

export default CarouselComponent;
