import React from "react";
import { Col, Container, Image, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Fees = () => {
  return (
    <div>
      <div className="innerHeader d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="#home" className="bold poppins black">
              What is Hero
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/what-hero">
                  Overview
                </Nav.Link>
                <Nav.Link as={Link} to="/how-it-works">
                  How It Works
                </Nav.Link>
                <Nav.Link as={Link} to="/fees" active>
                  Fees
                </Nav.Link>
                <Nav.Link as={Link} to="/getstarted">
                  Get Started
                </Nav.Link>
                <Nav.Link as={Link} to="/customer-future">
                  The future of Hero
                </Nav.Link>
                <Nav.Link as={Link} to="/customer-contact">
                  Contact us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <section className="protection sec_pd fees_sec1">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="sectionTitle">
                <h2 className="black poppins fz48 bold mb-4">
                  Cost effective
                  <br className="mobile-brtag"></br> and affordable
                </h2>
                <p className="mb-0 fz20 regular poppins black lh33">
                  Many other personal protection services are
                  <br className="mobile-brtag"></br> seen as exclusive, costly
                  and complicated to<br className="mobile-brtag"></br> arrange.
                  HERO’s intention is to democratize{" "}
                  <br className="mobile-brtag"></br>the way protection is
                  offered making it <br className="mobile-brtag"></br>accessible
                  to all.
                </p>
                <Link
                  to="/customer-signup"
                  className="btn btn-outline btn-outline-dark mT30 fz16"
                >
                  Sign up now
                </Link>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="imageIcon text-center">
                <Image className="w-100" src="./assets/img/fess2.png"></Image>
              </div>
            </Col>
          </Row>
          <hr className="color mT90"></hr>
        </Container>
      </section>

      <section className="securityEveryone ">
        <Container>
          <Row>
            <Col sm={12}>
              <h4 className="mB70 fz40 bold poppins black text-center">
                Personal security affordable for everyone.
              </h4>
            </Col>
            <Col sm={12} lg={6}>
              <div className="securityEveryone_box white text-center mb-3 mb-lg-0">
                <p className="fz16 bold poppins">
                  Personal security in New York
                </p>
                <h4 className="fz40 bold my-4 d-flex align-items-center justify-content-center">
                  $80-$200+ <small className="regular fz20"> /hr*</small>
                </h4>
                <p className="fz15 regular poppins mb-0">
                  *Generally requires a pre-arranged contract
                  <br className="mobile-brtag"></br> and longer minimum service
                  period.{" "}
                </p>
              </div>
            </Col>
            <Col sm={12} lg={6}>
              <div className="securityEveryone_box white text-center bggreen">
                <p className="fz16 bold poppins">
                  HERO personal security in New York
                </p>
                <h4 className="fz40 bold my-4 d-flex align-items-center justify-content-center">
                  $60 <small className="regular fz20"> /hr*</small>
                </h4>
                <p className="fz15 regular poppins mb-0">
                  *The flexibility of on-demand with a low 15 min
                  <br className="mobile-brtag"></br> minimum service, makes this
                  service accessible <br className="mobile-brtag"></br>and
                  affordable for everyone.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="crimePicture sec_pd fees_sec3">
        <Container>
          <div class="crimePicture_title fz40 bold poppins black mB70">
            How fees are calculated
          </div>
          <Row>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/control-journey.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Control your journey
                    </h4>
                  </div>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    You set your own course, set the pace and the duration. You
                    dictate the route but can take advice from the Hero,
                    ultimately the control is yours and the cost is reflective
                    of the journey you take.
                  </p>
                </div>
              </div>
            </Col>
            <hr className="d-block d-lg-none mt-4 mb-0"></hr>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-4 mb-lg-0 mt-4 mt-lg-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/time-allocation.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Time allocation
                    </h4>
                  </div>

                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Estimate how long you will need a Hero for and set the
                    duration of your service. The cost for the duration you
                    select will be displayed. When you get close to the end of
                    your allotted time you can easily extend the time period
                    from within the app.
                  </p>
                </div>
              </div>
            </Col>
            <hr className="d-block d-lg-none mt-4"></hr>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-0">
                <div class="whyHero_Wors_cardcontent ">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/minimum-payment.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Minimum assignment payments
                    </h4>
                  </div>

                  <p class="mb-0 fz15 regular poppins lh22 black">
                    The minimum payment per minimum assignment service is $15.00
                    {/* + optional tip */}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="personal_security">
        <Container>
          <h4 class="mB90 fz40 bold poppins black getstarted_title">
            {" "}
            We value your personal security{" "}
          </h4>
          <Row className="align-items-center">
            <Col sm={12} lg={5}>
              <div class="imgWrap">
                <Image src="assets/img/otherfess.png" class="w-100"></Image>
              </div>
            </Col>
            <Col>
              <div className="contentWrap p-5">
                <h4 className="black fz24 medium poppins mB20">Other fees</h4>
                <p className="lh25 fz16 regular poppins">
                  If the Hero needs to accompany you on public transport, an
                  additional charge will be made to cover their journey while
                  protecting you. Other fees may apply depending on where you
                  request the Hero to accompany you.
                </p>
                <Link
                  href="#"
                  className="fz15 poppins semi-bold mt-3 d-inline-block text-decoration-none black"
                >
                  {" "}
                  Learn more
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </Link>
              </div>
            </Col>
            <div class="mt-5"></div>
            <Col sm={12} lg={5}>
              <div class="imgWrap">
                <Image src="assets/img/service1.png" class="w-100"></Image>
              </div>
            </Col>
            <Col>
              <div className="contentWrap p-5">
                <h4 className="black fz24 medium poppins mB20">
                  Tipping for your service
                </h4>
                <p className="lh25 fz16 regular poppins">
                  After each assignment, you will have the opportunity to leave
                  a tip for providing a great service. Every tip will be passed
                  onto our Heroes, 100% in full.
                </p>
                <Link
                  href="#"
                  className="fz15 poppins semi-bold mt-3 d-inline-block text-decoration-none black"
                >
                  {" "}
                  Learn more
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </Link>
              </div>
            </Col>
            <div class="mt-5"></div>
            <Col sm={12} lg={5}>
              <div class="imgWrap">
                <Image src="assets/img/service2.png" class="w-100"></Image>
              </div>
            </Col>
            <Col>
              <div className="contentWrap p-5">
                <h4 className="black fz24 medium poppins mB20">
                  Get the most from your service
                </h4>
                <p className="lh25 fz16 regular poppins">
                  Remember that your service charge is based on the overall time
                  of your journey so be ready to greet your Hero at the meeting
                  point you entered into the app. This way your journey will get
                  off to a smooth start.
                </p>
                <p className="lh25 fz16 regular poppins">
                  If a small group books the service it can be a more
                  cost-effective way of hiring a Hero and do remember, stop-offs
                  or detours on route are going to add to your bill so plan your
                  route if you want to make the best use of your time.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Fees;
