import React from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import signupOnline from "../assets/images/signup-online.svg";
import downloadApp from "../assets/images/download-app.svg";
import bankingInfo from "../assets/images/banking-info.svg";
import uniformAndEquipment from "../assets/images/uniform-and-equipment.svg";
import begineersGuide from "../assets/images/begineers-guide.svg";
import prepare from "../assets/images/prepare.svg";
import DownloadApp from "../components/DownloadApp";
import ExploreMore from "../components/ExploreMore";
import Assignment from "../assets/images/explore-more.svg";

const HowtoUseApp = () => {
  const assignmentText = "Accepting and taking assignments" || "";

  return (
    <div>
      <div className="innerHeader d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="#home" className="bold poppins black">
              What is Hero
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="d-lg-block">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/hero-overview">
                  Overview
                </Nav.Link>
                <Nav.Link as={Link} to="/whatis-hero">
                  How to register
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-earning">
                  Earnings
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-how-it-works">
                  How it works
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-using-app" active>
                  Using the app
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-contact">
                  Sign up
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <section className="getstarted sec_pd mt-4 mt-lg-0">
        <Container>
          <h4 class="mB90 fz40 bold poppins black getstarted_title">
            {" "}
            Prepare to start using the app{" "}
          </h4>
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={signupOnline || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                {" "}
                1. Sign up online{" "}
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Follow our sign-up process to become a Hero operative. Read our
                section on requirements to register so you are fully prepared
                with the documents and information you need
              </span>
              <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Sign up now
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </div>
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={downloadApp || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                2. Download the app
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Download our app, which will be your main resource and guide for
                working with HERO.
              </span>
              <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Download now
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={bankingInfo || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                3. Complete your banking information
              </p>
              <span className="poppins fz14 regular d-block lh25">
                The first thing to do when entering the Hero app is to complete
                your banking information in order to receive your earnings.
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={uniformAndEquipment || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                4. Ensure you have received your uniform and any equipment
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Your ‘Welcome box’ will include: uniform, stickers, hero phone
                case, and welcome note. The uniform is reversible for both an
                incognito service (black only side) or uniformed (Hero branded).
                You will also receive a Hero identity card to be used at all
                times when on active duty.
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          {/* Continu from here */}
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={begineersGuide || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                5. Read the beginners guide
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Make sure you familiarize yourself with our code of conduct,
                understand our rules and any applicable state laws in the
                downloadable beginners guide. We are proud of our aims and proud
                for you to represent them.
              </span>
              <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Download guide
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={prepare || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                6. Prepare mentally and physically for your first assignment
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>
        </Container>
      </section>

      {/* <DownloadApp /> */}
      <ExploreMore text={assignmentText || " "} image={Assignment || ""} />
    </div>
  );
};

export default HowtoUseApp;
