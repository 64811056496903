import React from "react";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  NavItem,
  NavLink,
} from "react-bootstrap";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="homeHeader header">
      <Container fluid>
        <Navbar expand="lg">
          <Navbar.Brand as={Link} to="/">
            {" "}
            <img src="assets/img/logo.svg" alt="icon" />{" "}
          </Navbar.Brand>
          <div className="mobileMenu d-flex d-lg-none">
            <Nav as="ul" className="p-0 m-0">
              <NavItem as="li" className="nav-item mx-3"></NavItem>
              <NavItem as="li" className="nav-item mx-3">
                <NavLink
                  className="btn btn-secondary"
                  aria-current="page"
                  as={Link}
                  to="/hero-signup"
                >
                  {" "}
                  Sign up{" "}
                </NavLink>
              </NavItem>
            </Nav>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <img src="assets/img/humburger.svg" alt="icon" />
            </Navbar.Toggle>
          </div>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav as="ul" className="me-auto mb-2 mb-lg-0">
              <NavItem className="nav-item">
                <Nav.Link as={Link} to="/what-hero">
                  What is Hero
                </Nav.Link>
              </NavItem>
              <NavItem className="nav-item">
                <Nav.Link as={Link} to="company-home">
                  Company
                </Nav.Link>
                {/* <NavDropdown title="  Company" id="collasible-nav-dropdown">
                  <NavDropdown.Item as={Link} to="company-home">
                    About US
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="company-team">
                    Our Team
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="company-team">News</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="company-team">Blog</NavDropdown.Item>
                </NavDropdown> */}
              </NavItem>
              <NavItem className="nav-item">
                <Nav.Link as={Link} to="customer-safety">
                  Safety
                </Nav.Link>
                {/* <NavDropdown title="Safety" id="collasible-nav-dropdown">
                  <NavDropdown.Item as={Link} to="customer-safety">
                    Customer safety
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="hero-safety">
                    Hero safety
                  </NavDropdown.Item>
                </NavDropdown> */}
              </NavItem>
              <NavItem className="nav-item">
                <Nav.Link as={Link} to="help">
                  Help
                </Nav.Link>
              </NavItem>

              {/* <NavItem className="nav-item">
                  <Nav.Link as={Link} to="customer-safety">Safety</Nav.Link>
                <NavDropdown title="Help" id="collasible-nav-dropdown">
                  <NavDropdown.Item as={Link} to="help">Customer Help</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="help">Hero Help</NavDropdown.Item>
                </NavDropdown>
              </NavItem> */}
            </Nav>
            <Nav
              as="ul"
              className="navbar-nav d-none ms-auto mb-2 mb-lg-0 d-lg-flex"
            >
              {/* <NavItem className="nav-item">
                <NavLink className="login" aria-current="page" as={Link} to="#">
                  <img src="assets/img/login.svg" alt="img" /> Log in{" "}
                </NavLink>
              </NavItem> */}
              {/* <NavItem className="nav-item d-block ">
                <NavLink
                  className="nav-link active"
                  aria-current="page"
                  as={Link}
                  to="/#"
                >
                  {" "}
                  Services{" "}
                </NavLink>
              </NavItem> */}
              <NavItem className="nav-item">
                <Link
                  className="btn btn-secondary"
                  style={{ width: "120px" }}
                  aria-current="page"
                  // to="/hero-signup"
                  to="/customer-signup"
                >
                  {" "}
                  Contact Us{" "}
                </Link>
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};

export default Header;
