import React from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import viewRating from "../assets/images/viewRating.svg";
import reportingClient from "../assets/images/reportingClient.svg";
import provideRating from "../assets/images/provideRating.svg";
import overallRating from "../assets/images/overallRating.svg";
import explainRating from "../assets/images/explainRating.svg";
import DownloadApp from "../components/DownloadApp";
import ExploreMore from "../components/ExploreMore";
import StarRating from "../assets/images/StarRating.svg";

const HowRatingWorks = () => {
  const StarRatingText = "5-star assignments" || "";

  return (
    <div>
      <div className="innerHeader d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="#home" className="bold poppins black">
              What is Hero
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="d-lg-block">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/hero-overview">
                  Overview
                </Nav.Link>
                <Nav.Link as={Link} to="/whatis-hero">
                  How to register
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-earning">
                  Earnings
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-how-it-works">
                  How it works
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-using-app" active>
                  Using the app
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-contact">
                  Sign up
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <section className="getstarted sec_pd mt-4 mt-lg-0">
        <Container>
          <h4 class="mB90 fz40 bold poppins black getstarted_title">
            {" "}
            How star ratings work{" "}
          </h4>
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={provideRating || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                {" "}
                1. Provide a rating{" "}
              </p>
              <span className="poppins fz14 regular d-block lh25">
                After an assignment, the app gives you and your client, each the
                opportunity to provide a rating.{" "}
              </span>
              <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Sign up now
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </div>
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={explainRating || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                2. Explaining the rating
              </p>
              <span className="poppins fz14 regular d-block lh25">
                If you or your client chooses lower than 5 stars, the app may
                ask for a bit more feedback about why that rating was chosen.
              </span>
              <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Download now
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={reportingClient || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                3. Reporting a client
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={overallRating || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                4. Your overall rating
              </p>
              <span className="poppins fz14 regular d-block lh25">
                The app turns the ratings you get into an average that’s
                displayed to your clients before and during a trip. Your overall
                rating is an average of the last 500 ratings you’ve received.{" "}
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          {/* Continu from here */}
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={viewRating || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                5. Viewing your rating
              </p>
              <span className="poppins fz14 regular d-block lh25">
                You can see your ratings in your Hero app. Tap your profile
                photo in the upper left corner, then view your average rating
                underneath for details.
              </span>
              <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Download guide
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </div>
        </Container>
      </section>

      {/* <DownloadApp /> */}
      {/* Fix this issue, if assignmentText is not defined */}
      <ExploreMore
        text={StarRatingText || " "}
        image={StarRating || ""}
        link="/assignment-tips"
      />
    </div>
  );
};

export default HowRatingWorks;
