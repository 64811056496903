import React from "react";
import { Col, Container, Image, Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const HeroHowItWorks = () => {
  return (
    <div className="hero_howit_works">
      <div className="innerHeader d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="#home" className="bold poppins black">
              What is Hero
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="d-lg-block">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/hero-overview">
                  Overview
                </Nav.Link>
                <Nav.Link as={Link} to="/whatis-hero">
                  How to register
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-earning">
                  Earnings
                </Nav.Link>
                <Nav.Link as={Link} to="/how-it-works" active>
                  How it works
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-using-app">
                  Using the app
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-contact">
                  Contact us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <section className="safetyTechnology  sec_pd ">
        <Container>
          <Image
            className="d-block d-lg-none"
            src="assets/img/whatis-hero.png"
          ></Image>
          <Row className="align-items-center h-100 justify-content-start">
            <Col sm={12} lg={6}>
              <div className="sectionTitle">
                <h2 className="white poppins fz48 bold mB30">
                  How does the <br></br> app work?
                </h2>
                <p className="mb-3 fz20 regular poppins white lh30">
                  Our app supports your every step.
                </p>
                <p className="mb-0 fz20 regular poppins white lh30">
                  The HERO app helps you earn smarter with<br></br> in-app
                  features. Easy to use and reliable, <br></br> the app supports
                  you at every turn.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="herohome_screen sec_pd">
        <Container>
          <h4 class="mB20 fz40 bold poppins black herohome_screen_title">
            The home screen menu
          </h4>
          <Row className="align-items-center">
            <Col sm={12} lg={4} className="order2">
              <div className="herohome_screen_data poppins fz16 regular black lh25">
                <p>
                  <b>1. Profile -</b> Amend your personal details and
                  preferences here and check your rating.
                </p>
                <p>
                  <b>2. Balance </b> - See current balance, daily and monthly
                  earnings and access the cash out feature.
                </p>
                <p>
                  <b>3. History </b>- History -See your mission history and each
                  assignment showing date, duration and earnings.
                </p>
              </div>
            </Col>
            <Col sm={12} lg={4}>
              <Image
                className="mobileImage w-100"
                src="assets/img/mobile-mask.png"
              ></Image>
            </Col>
            <Col sm={12} lg={4} className="order2">
              <div className="herohome_screen_data poppins fz16 regular black lh25">
                <p>
                  <b> 4. FAQ and Help </b>- Handy information guidance can be
                  accessed from within the app.
                </p>
                <p>
                  <b>5. Legal -</b> See our terms, privacy policy, city
                  regulations and more.
                </p>
                <p>
                  <b>3. Support </b>- Call or email us from within the app with
                  any issues or questions.
                </p>
              </div>
            </Col>
          </Row>
          <hr className="d-none d-lg-block opacity-25 mT90"></hr>
        </Container>
      </section>

      <section className="mockupSec sec_pd pt-0">
        <Container>
          <Row className="align-items-center">
            <Col sm={12} lg={5} className="order2">
              <div className="mockupSec_content pR80">
                <h4 class="mB40 fz40 bold poppins black herohome_screen_title">
                  Schedule your time, your way
                </h4>
                <p className="fz16 regular poppins lh25">
                  Connect to the app’s ‘in-service’ mode freely by switching
                  between offline and online status, to control when you work.
                  The power is in your hands.
                </p>
              </div>
            </Col>
            <Col sm={12} lg={7}>
              <div className="mockupSec_img">
                <Image src="assets/img/mockup1.png"></Image>
              </div>
            </Col>
          </Row>
          <div className="sec_pd d-none d-lg-block"></div>
          <Row className="align-items-center">
            <Col sm={12} lg={7}>
              <div className="mockupSec_img">
                <Image src="assets/img/mockup2.png"></Image>
              </div>
            </Col>
            <Col sm={12} lg={5}>
              <div className="mockupSec_content pL80">
                <h4 class="mB40 fz40 bold poppins black herohome_screen_title">
                  Watch for your next assignment
                </h4>
                <p className="fz16 regular poppins lh25">
                  Service alerts will appear on your screen in ascending order,
                  the time required for the assignment, the place where it is
                  located, its rating and the approximate profit from the
                  service. This way you can choose which assignment to take.
                </p>
              </div>
            </Col>
          </Row>
          <div className="sec_pd d-none d-lg-block"></div>
          <Row className="align-items-center">
            <Col sm={12} lg={5} className="order2">
              <div className="mockupSec_content pR80">
                <h4 class="mB30 fz40 bold poppins black herohome_screen_title">
                  Track your<br></br> earnings
                </h4>
                <p className="fz16 regular poppins lh25">
                  Access your balance to see overall earnings, monthly and daily
                  totals and keep track of what you earn.
                </p>
              </div>
            </Col>
            <Col sm={12} lg={7}>
              <div className="mockupSec_img">
                <Image src="assets/img/mockup3.png"></Image>
              </div>
            </Col>
          </Row>
          <div className="sec_pd d-none d-lg-block"></div>
          <Row className="align-items-center">
            <Col sm={12} lg={8}>
              <div className="mockupSec_img">
                <Image src="assets/img/mockup4.png"></Image>
              </div>
            </Col>
            <Col sm={12} lg={4}>
              <div className="mockupSec_content pL80">
                <h4 class="mB20 fz40 bold poppins black herohome_screen_title">
                  Learn how to use the app
                </h4>
                <p className="fz16 regular poppins lh25">
                  Follow our simple guides to help you understand how to prepare
                  using the app, how to accept an assignment and how our rating
                  system works.
                </p>
                <Link className="blackBtn mt-3 d-inline-block d-lg-none ">
                  Learn the app basics
                </Link>
              </div>
            </Col>
          </Row>
          <div className="sec_pd pb-0 d-none d-lg-block"></div>
        </Container>
      </section>
    </div>
  );
};

export default HeroHowItWorks;

