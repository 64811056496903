import base from "./base";

export const getNews = (payload = {}) => {
  const { page = 1, perPage = 5 } = payload;

  return base.get(`/utils/news?page=${page}&perPage=${perPage}`);
};

export const getNewsDetails = (payload) => base.get(`/utils/news/${payload}`);

