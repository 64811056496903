import React from "react";
import { Container, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";

const CustomerSignupStep4 = () => {
    return (
        <div className="customer_signup sec_pd">
            <Container>
                <div className="customer_signup_process">
                    <div className="processTitle black medium fz20 poppins text-center mB20"> 
                    <NavLink className="d-flex align-items-center text-decoration-none text-dark" to="/customer-signup-progress">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M14 18L8 12L14 6" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> Back
                        </NavLink>
                    Download the app </div>
                    <div className="downloadApp_div w-50 m-auto text-center">
                        <Image className="downloadimg-ic" src="assets/img/download-app.png"></Image>
                        <p className="regular fz18 poppins text-center mB30 d-block d-lg-none">
                            Congratulations! <br></br> You are ready to get protected! <br></br>
                            Download the app to get started
                        </p>
                        <p className="regular fz18 poppins text-center mB30 d-none d-lg-block">
                            Congratulations!  You are ready to get protected! <br></br>
                            Download the app to get started
                        </p>
                        <div className="btnRow">
                            <a href="/#" className="me-2">
                                <img src="assets/img/google-play.svg" alt="icon" />
                            </a>
                            <a href="/#">
                                <img src="assets/img/app-store.svg" alt="icon" />{" "}
                            </a>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default CustomerSignupStep4;
