import React, { Fragment, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { uploadFile, uploadS3File } from "../api/util";
import { getHeroSignupProgress, uploadHeroDocs } from "../api/hero";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

const HeroSignupStep1 = () => {
  const navigate = useNavigate();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const S3BaseUrl = localStorage.getItem("S3BaseUrl") || "";
  const [isUploading, setIsUploading] = useState(false);
  //get user uploaded data;
  useEffect(() => {
    getHeroSignupProgress().then((response) => {
      if (response.data.success) {
        const heroRes = response?.data?.data?.drivingLicense || {};
        if (heroRes.isUploaded) {
          setSelectedFiles(heroRes?.image);
        }
      }
    });
  }, []);

  const handleFileUpload = async (e) => {
    const files = e.target.files;
    if (files.length == 0) return false;
    if (files.length > 2) return setShowAlert(true);
    //remove previous files
    setSelectedFiles([]);
    //set uploading true
    setIsUploading(true);
    try {
      const response = await uploadFile({
        location: "users/",
        type: "IMAGE",
        count: files.length,
      });

      const fileUrls = response.data.data;

      fileUrls.map(async (fileUrl, index) => {
        const { filename, preview, url } = fileUrl || {};
        const file = files[index];
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
          },
          body: file,
          redirect: "follow",
        };

        const result = await uploadS3File({
          url,
          requestOptions,
        });
        setSelectedFiles((selectedFiles) => [...selectedFiles, filename]);
        //enable submit button
        setIsSubmitBtnDisabled(false);
        //set uploading false
        setIsUploading(false);
      });
    } catch (err) {
      console.log(err);
      //set uploading false
      setIsUploading(false);
    }
  };

  const handleSubmit = async (number) => {
    const response = await uploadHeroDocs({
      type: "DRIVING",
      url: selectedFiles,
      number,
    });
    if (response?.data?.success) {
      navigate("/hero-signup-progress");
    }
  };

  const formik = useFormik({
    initialValues: {
      number: "",
    },
    validationSchema: yup.object().shape({
      number: yup
        .string()
        .trim()
        .matches(/^[a-zA-Z0-9]{6}$/, "Please enter valid number")
        .required("Please enter valid number"),
    }),
    onSubmit: (values) => {
      handleSubmit(values.number);
    },
  });

  return (
    <div className="customer_signup sec_pd">
      <Container>
        <div className="uploadyourProfile text-center">
          <div className="processTitle black medium fz20 poppins text-center mB40">
            <NavLink
              className="d-flex align-items-center text-decoration-none text-dark"
              to="/hero-signup-progress"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M14 18L8 12L14 6"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>{" "}
              Back
            </NavLink>
            Personal identification{" "}
          </div>
          <p className="fz16 regular poppins black">
            Upload a photo of your ID or driver's license
          </p>

          <span>
            Make sure that the document has not expired and deactivate the flash
            to avoid the reflection of the light in the photo. Make sure to
            include the four corners in the image and that the information is
            clear and legible.{" "}
          </span>

          <Row className="my-4">
            <Col>
              <div className="d-flex justify-content-center">
                <label className="black fz18 regular poppins px-2">
                  DL Number
                </label>
                <input
                  name="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.number}
                />
              </div>
              {formik.touched.number && formik.errors.number ? (
                <div
                  className="text-danger field-error fz12 dl_number_error"
                  // style={{ paddingLeft: "60px" }}
                >
                  {formik.errors.number}
                </div>
              ) : null}
            </Col>
          </Row>

          <div className="hero_step_one_disp">
            {selectedFiles.length > 0 ? (
              selectedFiles.map((selectedFile, index) => {
                return (
                  <Fragment key={index.toString()}>
                    <div
                      className="profilePic"
                      style={{
                        backgroundImage: `url(${S3BaseUrl}${selectedFile})`,
                      }}
                    ></div>
                  </Fragment>
                );
              })
            ) : (
              <div
                className="profilePic"
                style={{
                  backgroundImage: `url("assets/img/default_doc.png")`,
                }}
              ></div>
            )}
          </div>
          {showAlert && (
            <Alert
              style={{
                margin: "40px",
              }}
              variant="danger"
              onClose={() => setShowAlert(false)}
              dismissible
            >
              <p
                style={{
                  marginBottom: "0px",
                }}
              >
                You can upload maximum two files.{" "}
              </p>
            </Alert>
          )}
          <div className="btnRow w-50 m-auto mt-3">
            <div className={`uploadbtn ${isUploading ? "disabled" : ""}`}>
              {isUploading ? "Uploading..." : "Upload photo ID"}
              {isUploading && (
                <span
                  class="spinner-border spinner-border-sm text-white ms-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              <input type="file" onChange={handleFileUpload} multiple />
            </div>
            <Button
              className="submit-btn mt-3"
              onClick={formik.handleSubmit}
              disabled={isSubmitBtnDisabled}
            >
              Submit
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeroSignupStep1;

