import React from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Nav,
  NavItem,
  Row,
  Tab,
} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

const HeroHome = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section className="homeBanner heroHome">
        <Container fluid>
          <div className="tabDesign" defaultActiveKey="first">
            <Tab.Container>
              <Tab.Content>
                <Tab.Pane active eventKey="first">
                  <div className="mb-4">
                    <img src="assets/img/h-ic.svg" alt="img" />
                  </div>
                  <h4>
                    Be a Hero<br></br> and get paid
                  </h4>
                  <p className="fz28 medium poppins white mT20">
                    On-demand personal security
                  </p>
                  {/* <Link to="/hero-signup">
                    <Button
                      variant="outline-light"
                      type="button"
                      className="btn btn-outline btn-outline-light mt-4"
                    >
                      Sign up now
                    </Button>
                  </Link> */}
                </Tab.Pane>
              </Tab.Content>
              <Nav variant="pills" id="pills-tab">
                <NavItem role="presentation">
                  <Nav.Link
                    active
                    as={Link}
                    to="/customer-signup"
                    eventKey="second"
                  >
                    <p className="fz28 mb-0">Discover your calling</p>
                  </Nav.Link>
                </NavItem>
                <NavItem role="presentation">
                  <Nav.Link as={Link} to="/" eventKey="first">
                    <Image src="assets/img/security.svg" />
                    Get protection >>>
                  </Nav.Link>
                </NavItem>
              </Nav>
            </Tab.Container>
          </div>
        </Container>
      </section>
      {/* <section className="weHero sec_pd bglight">
        <Container>
          <div className="sectionTitle text-center">
            <h2 className="black poppins fz36 bold mb-3">What is Hero</h2>
            <p className="mb-0 fz22 regular poppins black lh33">
              HERO is a revolutionary new on-demand personal bodyguard app.
              <br />
              Quick and cost-effective, it’s a convenient way to request
              <br />
              personal security when you’re traveling around your city.
            </p>
          </div>
          <div className="imgWrap text-center mT70">
            <Image src="assets/img/wehero.svg"></Image>
          </div>
        </Container>
      </section> */}

      <section className="yourSafety sec_pd safetyhomeHero">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={5} sm={12} className="order2">
              <div className="imgWrap text-center mT70">
                <Image src="assets/img/safety1.svg" className="w-100"></Image>
              </div>
            </Col>
            <Col md={7} sm={12}>
              <div className="sectionTitle">
                <h2 className="blue poppins fz50 bold mb-3">
                  Protect your Community
                </h2>
                <p className="mb-0 fz28 regular poppins black lh35">
                  HERO is an{" "}
                  <span class="blue bold">on-demand security platform</span>{" "}
                  where true security Heroes shine and thrive.
                </p>
                <p className="mb-0 fz28 regular poppins black lh35">
                  Our app allows you to{" "}
                  <span class="blue bold">
                    offer your security services flexibly, find fulfillment, and
                    earn money.
                  </span>
                </p>
                {/* <Link
                  href="/#"
                  className="btn btn-outline btn-outline-primary mT30"
                >
                  Learn More
                </Link> */}
                <Button
                  onClick={() => {
                    navigate("/whatis-hero");
                  }}
                  className="btn btn-outline btn-outline-primary mT30"
                >
                  Get Started.
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="behindeCompany">
        <Container>
          <Row className="align-items-center">
            <Col xxl={10} xl={8} sm={12}>
              <div className="sectionTitle">
                <h2 className="white poppins fz50 bold mb-3">
                  Opportunity, Flexibility & Extra Income
                </h2>
                <p className="mb-0 fz28 regular poppins white lh35">
                  We are former or current law enforcement officers and licensed
                  security professionals - <b>just like you</b>. Earn up to{" "}
                  <b>$30 an hour</b> for protecting the community you love!
                </p>
                <Button
                  onClick={() => {
                    navigate("/company-home");
                  }}
                  className="btn btn-outline btn-outline-light mT30"
                >
                  Learn More
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="workus herohome_wrok">
        <Container>
          <Row className="align-items-center">
            <Col sm={7}>
              <div className="sectionTitle">
                <h2 className="white poppins fz50 bold mb-3">
                  You don’t join the Heroes... you become one!
                </h2>
                <p className="mb-0 fz28 regular poppins white lh35">
                  Whether you have a valid security license or want to get one,{" "}
                  <b>take the first step!</b>
                </p>
                <Button
                  onClick={() => {
                    // navigate("/hero-overview");
                    navigate("/hero-signup");
                    // hero-signup
                  }}
                  className="btn btn-outline btn-outline-light mT30"
                >
                  Become a Hero
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="beSafe sec_pd nycAvailable">
        <Container>
          <p className="white poppins fz70 mb-0 text-center">
            Now available in NYC!
          </p>
        </Container>
      </section>

      {/* <section className="downloadapp_sec  bglight">
        <Container>
          <div className="sectionTitle text-center">
            <h2 className="black poppins fz36 bold mb-3">
              This is just the beginning
            </h2>
          </div>

          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pR65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="assets/img/logo.svg"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br>the Hero app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Everyone deserves one
                </p>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pL65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="assets/img/logoblue.png"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br>I am a Hero app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Become a Hero
                </p>
              </div>
            </Col>
          </Row>

          <p className="mb-0 fz20 regular poppins black text-center ">
            Explore the beta version of our app, before <br></br>our full
            service launch - coming soon.
          </p>
        </Container>
      </section> */}
    </div>
  );
};

export default HeroHome;
