import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ExploreMore = (props) => {
  const { text, image, link = "/accept-assignment" } = props;
  const navigate = useNavigate();
  return (
    <div className="bgdarkGray sec_pd stepSec">
      <Container>
        <h4 className="stepSec_title mb-4 fz48 bold poppins black">
          Explore more topics
        </h4>
        <Link className="overview black fz20 regular">Return to overview</Link>
        <Row>
          <Col sm={12} lg={6}>
            <div className="downloadapp_sec_box exploreMoreCard">
              <div
                className="downloadapp_sec_box_inner"
                onClick={() => {
                  navigate(link);
                }}
              >
                <figure class="bg-transparent p-0">
                  <Image className="w-100" src={image || ""}></Image>
                </figure>
                <div className="content ps-4 w-50">
                  <p className="mb-0 fz24 medium poppins black">{text}</p>
                </div>
                <svg
                  className="rightIcon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ExploreMore;
