import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Container, Form, InputGroup, Nav, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import parse from 'html-react-parser';
import { getSubTopics, getTopics } from "../api/Topics";


const HelpTypeRoutes = {
    Hero: 'help',
    Customer: 'customer-help'
}

const CustomerHelp = () => {
    const location = useLocation();
    const [topicsData, setTopicsData] = useState([]);
    const [parentData, setParentData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [topicDetails, setTopicDetails] = useState({});
    const [currentType, setCurrentType] = useState('Hero');
    const navigate = useNavigate();
    const { slug } = useParams();
    const perPage = 7

    useEffect(() => {
        if (slug) {
            getSubTopics(slug)
                .then((response) => {
                    if (response.data.data.details) {
                        setTopicsData([]);
                        setTopicDetails(response.data.data.details);
                        const data = [{
                            slug: response.data.data.parents.slug,
                            topicName: response.data.data.parents.topicName,
                        }];
                        data.push({
                            slug,
                            topicName: response.data.data.details.Subtopicname
                        })
                        setParentData(data)
                    } else {
                        setTopicDetails({});
                        setTopicsData(response.data.data.subtopic);
                        setParentData([{
                            slug: response.data.data.parents.slug,
                            topicName: response.data.data.parents.topicName,
                        }])
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        } else {
            getTopics({
                page: currentPage,
                perPage,
                type: currentType
            })
                .then((response) => {
                    setParentData([]);
                    setTopicDetails({});
                    setTopicsData(response.data.data);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [slug, currentPage, currentType]);

    useEffect(() => {
        setCurrentType(location.pathname.includes('customer-help') ? 'Customer' : 'Hero');
    }, [location.pathname]);

    const onTopicClick = (type) => {
        
        if (currentType === 'Hero') navigate(`/help/${type}`);
        if (currentType === 'Customer') navigate(`/customer-help/${type}`);
    }

    const handleType = (type) => {
        setCurrentType(type);
        navigate(`/${HelpTypeRoutes[type]}`)
        //if (currentType === 'Hero') navigate(`/${HelpTypeRoutes[type]}`)
        //if (currentType === 'Customer') navigate(`/${HelpTypeRoutes[type]}`)
        
    }

    return (
        <div>
            <div className="innerHeader d-none d-lg-block">
                <Navbar expand="lg">
                    <Container fluid>
                        <Row className="w-100">
                            <Col sm={2}>
                                <Navbar.Brand as={Link} to={`/${HelpTypeRoutes[currentType]}`} className="bold poppins black">
                                    Help
                                </Navbar.Brand>
                            </Col>
                            <Col sm={10}>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse className="helpforcustomerBread" id="basic-navbar-nav">
                                    <Nav>
                                        <Nav.Link active={currentType === 'Hero'} onClick={() => handleType("Hero")}>
                                            Heros
                                        </Nav.Link>
                                        <Nav.Link active={currentType === 'Customer'} onClick={() => handleType("Customer")}>Customers</Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Col>
                        </Row>
                    </Container>
                </Navbar>
            </div>

            <section className="helpSec sec_pd">
                <Container className="smallContainer">
                    <div className="sectionTitle">
                        <h2 className="black poppins fz48 bold mB20">Help For {currentType}s</h2>
                    </div>
                    <div>
                        <Link className="bold poppins black medium fz12 text-decoration-none" to={`/${HelpTypeRoutes[currentType]}`}> Help </Link>
                        {parentData?.length > 0 && parentData.map((parent, index) => {
                            return (
                                <Link key={index} className="bold poppins black medium fz12 text-decoration-none" to={`/${HelpTypeRoutes[currentType]}/${parent?.slug}`}>
                                    &gt; {parent?.topicName}{" "}
                                </Link>
                            )
                        })}
                    </div>

                    <div className="searchforHelp mT20">
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1"><svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M31.6992 26.3752L24.8734 19.8752C26.2819 17.8494 27.1052 15.4239 27.1052 12.8128C27.1052 5.7351 21.0837 0 13.6525 0C6.22132 0 0.199219 5.7351 0.199219 12.8128C0.199219 19.8905 6.2219 25.6256 13.6519 25.6256C16.3935 25.6256 18.9418 24.8415 21.067 23.5L27.8928 30C29.1422 28.8227 30.5259 27.4498 31.6986 26.3752H31.6992ZM13.6519 21.7817C8.45892 21.7817 4.23509 17.7572 4.23509 12.8128C4.23509 7.86847 8.4595 3.8439 13.6519 3.8439C18.8444 3.8439 23.0688 7.86737 23.0688 12.8128C23.0688 17.7572 18.8432 21.7817 13.6519 21.7817Z" fill="black" />
                            </svg>
                            </InputGroup.Text>
                            <Form.Control
                                placeholder="Search questions, keywords, topics"
                                aria-label="Username"
                                aria-describedby="basic-addon1" />
                        </InputGroup>
                    </div>

                    {topicDetails && Object.keys(topicDetails).length > 0 ? (
                        <Accordion defaultActiveKey="0" className="signup_process">
                            <Accordion.Item eventKey="1" className="border-0" defaultActiveKey="1">
                                {(parse(topicDetails?.Subtopicdescription || ''))}
                            </Accordion.Item>
                        </Accordion>
                    ) : (
                        <>
                            <h2 className="alltopicTitle fz36 poppins medium black mT60 mB10">
                                {
                                    slug ? 'Sub Topics' : 'All Topics'
                                }
                            </h2>
                            <Accordion className="signup_process" activeKey="0">
                                {topicsData.map((topic, idx) => {
                                    return (
                                        <Accordion.Item className="border-0" key={idx} onClick={() => onTopicClick(topic?.slug)}>
                                            <Accordion.Header>{slug ? topic?.Subtopicname : topic?.topicName}</Accordion.Header>
                                        </Accordion.Item>
                                    )
                                })
                                }
                            </Accordion>
                        </>
                    )}


                    {/* <div className="anythingElse mT50 d-flex align-items-center justify-content-between">
                        <p className="mb-0 fz20 medium poppins black"> Can we help with anything else? </p>
                        <div className="btnRow">
                            <Button
                                onClick={() => {
                                    navigate("company-home");
                                }}
                                className="btn btn-outline btn-outline-dark me-lg-3 mb-2 mb-lg-0">
                                Yes
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate("company-home");
                                }}
                                className="btn btn-outline btn-outline-dark">
                                No
                            </Button>
                        </div>
                    </div> */}

                    <h2 className="alltopicTitle fz36 poppins medium black mT90 mB20"> Support messages </h2>

                    <Accordion defaultActiveKey="0" className="signup_process">
                        <Accordion.Item eventKey="10" className="border-0">
                            <Accordion.Header>  View all messages </Accordion.Header>
                            <Accordion.Body className="p-0 pt-4">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                </Container>
            </section>

        </div>
    );
};

export default CustomerHelp;
