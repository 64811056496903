import React from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

const HeroUsingApp = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="innerHeader d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="#home" className="bold poppins black">
              What is Hero
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="d-lg-block">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/hero-overview">
                  Overview
                </Nav.Link>
                <Nav.Link as={Link} to="/whatis-hero">
                  How to register
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-earning">
                  Earnings
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-how-it-works">
                  How it works
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-using-app" active>
                  Using the app
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-contact">
                  Contact us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <section className="herousing_app getstarted_sec1">
        <Container>
          <Row className="align-items-center">
            <Col md={6} sm={12} className="order2">
              <div className="sectionTitle mB40">
                <h2 className="black poppins fz48 bold mb-4">Using the app</h2>
                <p className="mb-0 fz20 regular poppins black lh33 mb-3">
                  Get the most from using HERO.
                </p>
                <p className="mb-0 fz20 regular poppins black lh33">
                  Whether you're preparing for your role as a Hero, <br></br>
                  accepting an assignment or working out how to <br></br>provide
                  a 5 star service find out how the app can <br></br> help you.
                </p>
              </div>
            </Col>

            <div className="imageIcon text-center">
              <Image src="assets/img/register2.png"></Image>
            </div>
          </Row>
        </Container>
      </section>
      <section className="downloadapp_sec downloadapp_sec_new">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box">
                <div className="appTitle mb-5">
                  <p className="fz40 bold poppins black ">Before you start</p>
                  <span className="poppins fz15 reguar black">
                    From signing up to understanding the role, here is a handy
                    guide to help you get off to a great start.
                  </span>
                </div>
                <div
                  onClick={() => {
                    navigate("/how-to-use-app");
                  }}
                  variant="none"
                  className="downloadapp_sec_box_inner"
                >
                  <figure className="bg-transparent p-0">
                    <Image className="w-100" src="assets/img/ic1.png"></Image>
                  </figure>
                  <div className="content ps-4 w-50">
                    <p className="mb-0 fz24 medium poppins black text-left">
                      Prepare for starting to use the app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box">
                <div className="appTitle mb-5">
                  <p className="fz40 bold poppins black">
                    Taking an assignment
                  </p>
                  <span className="poppins fz15 reguar black">
                    Here, you’ll find the basics of how the service works
                    through the app. From how to take assignments to earnings
                    and reporting, learn more in this section.
                  </span>
                </div>
                <div
                  className="downloadapp_sec_box_inner"
                  onClick={() => {
                    navigate("/accept-assignment");
                  }}
                >
                  <figure className="bg-transparent p-0">
                    <Image className="w-100" src="assets/img/ic2.png"></Image>
                  </figure>
                  <div className="content ps-4 w-50">
                    <p className="mb-0 fz24 medium poppins black">
                      Accepting and taking an assignment
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="downloadapp_sec downloadapp_sec_new sec_pd pt-0">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <div className="appTitle mb-4">
              <p className="fz40 bold poppins black ">Ratings and community</p>
              <span className="poppins fz15 reguar black">
                Find out how ratings work, and get tips from our team <br></br>
                to help you become an 5-star Hero in no time.
              </span>
            </div>
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box">
                <div
                  className="downloadapp_sec_box_inner"
                  onClick={() => {
                    navigate("/how-rating-works");
                  }}
                >
                  <figure className="bg-transparent p-0">
                    <Image className="w-100" src="assets/img/ic3.png"></Image>
                  </figure>
                  <div className="content ps-4 w-50">
                    <p className="mb-0 fz24 medium poppins black text-left">
                      How star ratings work
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box">
                <div
                  className="downloadapp_sec_box_inner"
                  onClick={() => {
                    navigate("/assignment-tips");
                  }}
                >
                  <figure className="bg-transparent p-0">
                    <Image className="w-100" src="assets/img/ic4.png"></Image>
                  </figure>
                  <div className="content ps-4 w-50">
                    <p className="mb-0 fz24 medium poppins black text-left">
                      5-star assignments
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
              </div>
            </Col>
          </Row>

          <p className="mb-0 fz20 regular poppins black text-left mt-3 d-none d-lg-block">
            Our community features will be coming soon!
          </p>
        </Container>
      </section>
    </div>
  );
};

export default HeroUsingApp;

