import React from "react";
import { Container, Image, Col, Row, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const CompanyFounder = () => {
  return (
    <div>
      <div className="innerHeader d-none d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand
              as={Link}
              to="/company-founder"
              className="bold poppins black"
            >
              Company
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/company-founder" className="active">
                  About us
                </Nav.Link>
                {/* <Nav.Link as={Link} to="/company-team">
                  Our Team
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="/fees">News</Nav.Link> */}
                {/* <Nav.Link as={Link} to="/getstarted">Blog</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <section className="founderInfo sec_pd">
        <Container>
          <Link className="fz14 regular poppins lh22 black  mB30 d-inline-block d-block d-lg-none">
            Back to about us
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="20"
              height="20"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
            </svg>
          </Link>
          <h4 className="fz40 bold poppins black founderInfo_title">
            A word from Jorge Ramos,<br></br>
            Chief Executive Officer
          </h4>
          <div className="founderInfo_intro">
            <Row className="g-0">
              <Col sm={12} md={6}>
                <div className="founderInfo_intro_content h-100">
                  Every day, our technology helps people walk the streets in New
                  York in safety. Helping keep people safe is a huge
                  responsibility and one we do not take lightly.
                </div>
              </Col>
              <Col sm={12} md={6}>
                <div className="imgWrap">
                  <Image src="assets/img/jorge-ramos-ortiz.jpg"></Image>
                </div>
              </Col>
            </Row>
          </div>
          <div className="founderInfo_content">
            <p>
              In today's world, feeling safe has become a rare commodity. But
              imagine a world where you could always feel secure. What if a Hero
              was always by your side? No more danger. With Hero, you're not
              alone. We accompany you, ensuring a safer path for you and your
              family. Heros are ready at any moment with a singular mission:
              your protection. Once you choose Hero, your safety is our
              priority, leaving you with just one responsibility <br></br>-
              enjoying life.
            </p>

            <p>Jorge Ramos Ortiz </p>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default CompanyFounder;

