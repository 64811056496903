import heroBase from "./heroBase";

export const heroRequestOtp = (payload) =>
  heroBase.post("/hero/hero-request-otp", payload);
export const heroSignup = (payload) =>
  heroBase.post("/hero/hero-signup", payload);
export const getHeroSignupProgress = () => heroBase.get("/hero/become-hero");
export const uploadHeroDocs = (payload) =>
  heroBase.post("/hero/update-license", payload);
export const heroForm = (payload) => heroBase.post("/hero/hero-form", payload);
export const heroBackgroundCheck = (payload) =>
  heroBase.post("/hero/background-check", payload);
export const getHeroEngagementAgreement = () =>
  heroBase.get("/pages/hero-engagement-agreement");
