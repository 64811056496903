import React from "react";
import "../../src/App.css";
import Header from "./Header";
import Home from "../pages/Home";
import Footer from "./Footer";
import { Route, Routes } from "react-router";
import HeroHome from "../pages/Hero-home";
import WhatHero from "../pages/WhatHero";
import CustomerFuture from "../pages/Customer-future";
import CustomerContact from "../pages/Customer-contact";
import WhyWeNeed from "../pages/Why-we-need";
import HowItWorks from "../pages/How-it-works";
import CustomerSafety from "../pages/Customer-safety";
import HeroSafety from "../pages/Hero-safety";
import SafetyResources from "../pages/Safety-resources";
import Fees from "../pages/Fees";
import GetStarted from "../pages/Get-started";
import CompanyFounder from "../pages/Company-founder";
import CompanyTeam from "../pages/Company-team";
import CompanyHome from "../pages/Company-home";
import Help from "../pages/Help";
import WhatisHero from "../pages/Whatis-hero";
import HeroOverview from "../pages/Hero-overview";
import HeroEarning from "../pages/Hero-earning";

import HowtoUseApp from "../pages/HowtoUseApp";
import AcceptAssignment from "../pages/AcceptAssignment";
import HowRatingWorks from "../pages/HowRatingWorks";
import AssignmentTips from "../pages/AssignmentTips";
import HeroUsingApp from "../pages/Hero-using-app";
import HeroContact from "../pages/Hero-contact";
import HeroHowItWorks from "../pages/HeroHowItWorks";
import CustomerHelp from "../pages/CustomerHelp";
import Login from "../pages/Login";
import CustomerSignup from "../pages/CustomerSignup";
import CustomerSignupProgress from "../pages/CustomerSignupProgress";
import CustomerSignupStep1 from "../pages/CustomerSignupStep1";
import CustomerSignupStep2 from "../pages/CustomerSignupStep2";
import CustomerSignupStep3 from "../pages/CustomerSignupStep3";
import CustomerSignupStep4 from "../pages/CustomerSignupStep4";
import HeroSignup from "../pages/HeroSignup";
import HeroSignupProgress from "../pages/HeroSignupProgress";
import HeroSignupStep1 from "../pages/HeroSignupStep1";
import HeroSignupStep2 from "../pages/HeroSignupStep2";
import HeroSignupStep3 from "../pages/HeroSignupStep3";
import HeroSignupStep4 from "../pages/HeroSignupStep4";
import HeroSignupStep5 from "../pages/HeroSignupStep5";
import CompanyNews from "../pages/CompanyNews";
import CompanyNews3 from "../pages/NewsDetails";

import CompanyBlog from "../pages/CompanyBlog";
import HeroApplication from "../pages/HeroApplication";
import NewsRoom from "../pages/NewsRoom";
import NewsDetails from "../pages/NewsDetails";
import ProtectedRoute from "./ProtectedRoute";
import BlogDetails from "../pages/BlogDetails";
import StaticPage from "../pages/StaticPage";

const RoutesComponent = () => {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="hero-home" exact element={<HeroHome />} />
        <Route path="what-hero" exact element={<WhatHero />} />
        <Route path="customer-future" exact element={<CustomerFuture />} />
        <Route path="customer-contact" exact element={<CustomerContact />} />
        <Route path="whywe-need" exact element={<WhyWeNeed />} />
        <Route path="how-it-works" exact element={<HowItWorks />} />
        <Route path="customer-safety" exact element={<CustomerSafety />} />
        <Route path="hero-safety" exact element={<HeroSafety />} />
        <Route path="safety-resource" exact element={<SafetyResources />} />
        <Route path="fees" exact element={<Fees />} />
        <Route path="getstarted" exact element={<GetStarted />} />
        <Route path="company-founder" exact element={<CompanyFounder />} />
        <Route path="company-team" exact element={<CompanyTeam />} />
        <Route path="company-home" exact element={<CompanyHome />} />
        {/* <Route path="help" exact element={<Help />} /> */}
        <Route path="whatis-hero" exact element={<WhatisHero />} />
        <Route path="hero-overview" exact element={<HeroOverview />} />
        <Route path="hero-earning" exact element={<HeroEarning />} />
        <Route path="how-to-use-app" exact element={<HowtoUseApp />} />
        <Route path="accept-assignment" exact element={<AcceptAssignment />} />
        <Route path="how-rating-works" exact element={<HowRatingWorks />} />
        <Route path="assignment-tips" exact element={<AssignmentTips />} />
        <Route path="hero-using-app" exact element={<HeroUsingApp />} />
        <Route path="hero-contact" exact element={<HeroContact />} />
        <Route path="hero-how-it-works" exact element={<HeroHowItWorks />} />
        <Route path="help">
          <Route index element={<CustomerHelp />} />
          <Route path=":slug" exact element={<CustomerHelp />} />
        </Route>
        <Route path="customer-help">
          <Route index element={<CustomerHelp />} />
          <Route path=":slug" exact element={<CustomerHelp />} />
        </Route>
        <Route path="login" exact element={<Login />} />

        <Route path="customer-signup" exact element={<CustomerSignup />} />
        <Route
          path="customer-signup-progress"
          exact
          element={
            <ProtectedRoute>
              <CustomerSignupProgress />
            </ProtectedRoute>
          }
        />
        <Route
          path="customer-signup-step-1"
          exact
          element={
            <ProtectedRoute>
              <CustomerSignupStep1 />
            </ProtectedRoute>
          }
        />
        <Route
          path="customer-signup-step-2"
          exact
          element={
            <ProtectedRoute>
              <CustomerSignupStep2 />
            </ProtectedRoute>
          }
        />
        <Route
          path="customer-signup-step-3"
          exact
          element={
            <ProtectedRoute>
              <CustomerSignupStep3 />
            </ProtectedRoute>
          }
        />
        <Route
          path="customer-signup-step-4"
          exact
          element={
            <ProtectedRoute>
              <CustomerSignupStep4 />
            </ProtectedRoute>
          }
        />
        <Route
          path="hero-signup-step-1"
          exact
          element={
            <ProtectedRoute>
              <HeroSignupStep1 />
            </ProtectedRoute>
          }
        />
        <Route path="hero-signup" exact element={<HeroSignup />} />
        <Route
          path="hero-signup-progress"
          exact
          element={
            <ProtectedRoute>
              <HeroSignupProgress />
            </ProtectedRoute>
          }
        />
        <Route
          path="hero-signup-step-1"
          exact
          element={
            <ProtectedRoute>
              <HeroSignupStep1 />
            </ProtectedRoute>
          }
        />

        <Route
          path="hero-signup-step-2"
          exact
          element={
            <ProtectedRoute>
              <HeroSignupStep2 />
            </ProtectedRoute>
          }
        />

        <Route
          path="hero-signup-step-3"
          exact
          element={
            <ProtectedRoute>
              <HeroSignupStep3 />
            </ProtectedRoute>
          }
        />

        <Route
          path="hero-signup-step-4"
          exact
          element={
            <ProtectedRoute>
              <HeroSignupStep4 />
            </ProtectedRoute>
          }
        />

        <Route
          path="hero-signup-step-5"
          exact
          element={
            <ProtectedRoute>
              <HeroSignupStep5 />
            </ProtectedRoute>
          }
        />

        <Route path="newsroom">
          <Route index element={<NewsRoom />} />
          <Route path="news">
            <Route index element={<CompanyNews />} />
            <Route path=":slug" exact element={<NewsDetails />} />
          </Route>
        </Route>
        <Route path="blog">
          <Route index element={<CompanyBlog />} />
          <Route path=":slug" exact element={<BlogDetails />} />
        </Route>
        {/* <Route path="blog" exact element={<CompanyBlog />} /> */}

        <Route path="heroapplication" exact element={<HeroApplication />} />
        <Route path="customer-privacy-policy" exact element={<StaticPage />} />
        <Route
          path="customer-terms-conditions"
          exact
          element={<StaticPage />}
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default RoutesComponent;

//https://constantinfo.net:1225/api/utils/blog/how-my-internship-expand-my-horizon
