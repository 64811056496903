import React from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
// import status from "../assets/images/status.svg";
import startService from "../assets/images/startService.svg";
import reportMaking from "../assets/images/reportMaking.svg";
import recieveServiceAlert from "../assets/images/recieveServiceAlert.svg";
import payments from "../assets/images/payments.svg";
import endService from "../assets/images/endService.svg";
import earningsFromClient from "../assets/images/earningsFromClient.svg";
import acceptServiceAlert from "../assets/images/acceptServiceAlert.svg";

import DownloadApp from "../components/DownloadApp";
import ExploreMore from "../components/ExploreMore";
import Ratings from "../assets/images/ratings.svg";

const AcceptAssignment = () => {
  const ratingText = "How star ratings work" || "";

  return (
    <div>
      <div className="innerMobile d-none d-block d-lg-none p-4 pb-0">
        <a
          href="#home"
          className="bold fz16 poppins black text-decoration-none"
        >
          What is Hero
        </a>
      </div>
      <div className="innerHeader d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="#home" className="bold poppins black">
              What is Hero
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className=" d-lg-block">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/hero-overview">
                  Overview
                </Nav.Link>
                <Nav.Link as={Link} to="/whatis-hero">
                  How to register
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-earning">
                  Earnings
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-how-it-works">
                  How it works
                </Nav.Link>
                <Nav.Link active as={Link} to="/hero-using-app">
                  Using the app
                </Nav.Link>
                <Nav.Link as={Link} to="/hero-contact">
                  Sign up
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <section className="getstarted sec_pd mt-4 mt-lg-0">
        <Container>
          <h4 class="mB90 fz40 bold poppins black getstarted_title">
            {" "}
            Accepting and taking an assignment{" "}
          </h4>
          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={recieveServiceAlert || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                {" "}
                1. Online and offline status{" "}
              </p>
              <span className="poppins fz14 regular d-block lh25">
                You will be able to navigate within the APP without risk of
                receiving a service offer when your status is "Offline". Once
                online, receive a service at any time even if you minimize the
                APP. The status automatically changes to "Offline" when the
                battery level of your device reaches 20%, if this happens during
                an assignment, it will not be canceled. However, when that
                assignment is complete your status will change to “Offline".
              </span>
              <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Sign up now
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src="./assets/img/st1.png"></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                2. Receiving a service alert
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Service alerts will appear on your screen in ascending order,
                the time required for the assignment, the place where it is
                located, its rating and the approximate profit from the service
                (tips and additional expenses not included).{" "}
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={acceptServiceAlert || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                3. Accepting a service
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Once the service is accepted, your reservation will be completed
                and you will be given directions to get to the customer's
                position. Information required to address and behave with the
                client is also shown: Name and surname, Qualification, Suffix,
                Preferred accompanying position.{" "}
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={startService || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                4. Starting a service
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Once you are in the correct position with the client and you
                have greeted the client by title and surname (ie. Mrs Jones) and
                confirmed your identity, the start mission button will be
                activated and the service time will begin when you click.
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          {/* Continu from here */}

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={endService || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                5. Ending a service
              </p>
              <span className="poppins fz14 regular d-block lh25">
                With 5 minutes to finish the service, a countdown notification
                will appear and you will be asked to inform the client of the
                situation. The client at that time can decide if they need more
                than the estimated time (extra time) or otherwise the service
                will end at the agreed time.
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={earningsFromClient || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">
                6. Earnings and rating a client
              </p>
              <span className="poppins fz14 regular d-block lh25">
                Once the service is finished, you will be able to see your
                current earnings, which will be updated when you receive a tip,
                or added expenses. You will also see the button to rate the
                client, this step is mandatory at the end of the assignment.
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={reportMaking || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">7. Making a report</p>
              <span className="poppins fz14 regular d-block lh25">
                A report must be created noting any events or incidents, this is
                completed through stars and a comment option, if a hero does not
                add any comment, they affirm that the service was completed
                successfully without any incident or setback.
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>

          <div className="getstarted_step d-flex">
            <figure className="mb-0">
              <Image src={payments || ""}></Image>
            </figure>
            <div className="contentWrap black">
              <p className="poppins fz20 regular d-block">8. Payments</p>
              <span className="poppins fz14 regular d-block lh25">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </span>
              {/* <Link
                href="#"
                className="fz15 poppins semibold mt-3 d-inline-block text-decoration-none black button"
              >
                {" "}
                Get help
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link> */}
            </div>
          </div>
        </Container>
      </section>

      {/* <DownloadApp /> */}
      {/* Fix this issue, if assignmentText is not defined */}
      <ExploreMore
        text={ratingText || " "}
        image={Ratings || ""}
        link="/how-rating-works"
      />
    </div>
  );
};

export default AcceptAssignment;
