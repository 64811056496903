import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
const CustomerSafety = () => {
  return (
    <div>
      <section className="safetyTechnology sec_pd customerSafety">
        <Container>
          <Row className="align-items-center h-100">
            <Col sm={12} lg={5}>
              <div className="sectionTitle">
                <h2 className="white poppins fz48 bold mB40">
                  Comprehensive <br></br> safety features
                </h2>
                <p className="mb-0 fz24 medium poppins white lh36">
                  We use the technology of HERO to keep you safe so you can get
                  on with the things that matter most. You can rest assured that
                  our services are monitored and reviewed by security and
                  technology experts.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="whyHero_Wors sec_pd safety_sec2">
        <Container>
          <div className="whyHero_Wors_title fz40 bold poppins black mB70">
            Peace of mind for your journey
          </div>
          <Row>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/prescence-ic.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    Preventative presence
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    Walking alone is more dangerous for the simple reason that
                    you’re an easier target for criminals. With a Hero by your
                    side criminals are less likely to even attempt it, knowing
                    they are more likely to be caught or injured in the process.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/support2.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    24/7 incident support
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    The Hero support team is trained in incident response and
                    is available around the clock..
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/trained-ic.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    Trained, vetted operatives
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    All of our Heroes undergo rigorous vetting procedures so you
                    can be reassured of their trustworthiness. They are, in many
                    instances, more highly trained individuals who are dedicated
                    to the skills of protection.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/rating-ic.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    2-way ratings
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    
                    Your feedback matters. Low-rated services are logged, and incidents reviewed. Our operatives may be removed if they do not perform to our high standards. We take our responsibilities to protect the HERO community seriously.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/phone-ic.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    Phone anonymisation
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                    If you need to contact your Hero through the app, your phone
                    number can stay private.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} sm={12}>
              <div className="whyHero_Wors_card">
                <Image src="assets/img/gps-tracking.svg"></Image>
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB20 fz20 bold poppins  black">
                    GPS tracking
                  </h4>
                  <p className="mb-0 fz15 regular poppins lh22 black">
                  All Hero assignment journeys are tracked from start to finish, so there’s a record of your trip if something happens. We also monitor safety distance between Hero and customer.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <hr className="color"></hr>
        </Container>
      </section>

      <section className="howhero_help sec_pd pt-0">
        <Container>
          <Row className="align-items-center h-100">
            <Col sm={12} md={12} lg={6}>
              <figure>
                <Image src="assets/img/s-5.png"></Image>
              </figure>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <div className="whyHero_Wors_card p-0 shadow-none mb-0">
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB15 fz20  bold poppins  black">
                    Our Hero code of conduct
                  </h4>
                  <p className="mb-0 fz16 regular poppins lh25 black">
                    All of our Heroes must follow our strict code of<br></br>{" "}
                    conduct that ensures they behave with integrity<br></br> and
                    with the safety of their customer utmost in <br></br>their
                    minds at all times.
                  </p>
                </div>
              </div>
            </Col>

            <div className="mt-5"></div>
            <Col sm={12} md={12} lg={6} className="order2">
              <div className="whyHero_Wors_card p-0 shadow-none pR40 mb-0">
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB15 fz20  bold poppins  black">
                    Knowledge is power
                  </h4>
                  <p className="mb-0 fz16 regular poppins lh25 black">
                  Discover some of our safety articles to help broaden your knowledge and practical tips around the issues of staying safe.
                  </p>
                  {/* <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Read more about safety
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                    </svg>
                  </Link> */}
                </div>
              </div>
            </Col>
            <Col sm={12} md={12} lg={6} className="order1">
              <figure>
                <Image src="assets/img/s-4.png"></Image>
              </figure>
            </Col>
            <div className="mt-5"></div>

            <Col sm={12} md={12} lg={6}>
              <figure>
                <Image src="assets/img/s-3.png"></Image>
              </figure>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <div className="whyHero_Wors_card p-0 shadow-none mb-0">
                <div className="whyHero_Wors_cardcontent mT40">
                  <h4 className="mB15 fz20 bold poppins  black">
                    Reporting procedures
                  </h4>
                  <p className="mb-0 fz16 regular poppins lh25 black">
                    HERO’s guidelines and rules of conduct help everyone{" "}
                    enjoy stress-free protection. The power however,
                    always remains in your hands with our reporting<br></br>{" "}
                    feature. If a Hero is reported by you we will investigate{" "}
                    and the Hero may be removed from our service for
                    the safety of the entire HERO community.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default CustomerSafety;
