import React from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Nav,
  NavItem,
  Row,
  Tab,
} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section className="homeBanner">
        <Container fluid>
          <div className="tabDesign" defaultActiveKey="first">
            <Tab.Container>
              <Tab.Content>
                <Tab.Pane active eventKey="first">
                  <div className="mb-4">
                    <Image src="assets/img/security.svg" />
                  </div>
                  <h4>
                    Everyone<br></br> deserves a Hero!
                  </h4>
                  <p className="white fz28 mb-0">
                    Affordable, on-demand personal security.
                  </p>
                  {/* <Button
                    variant="outline-light"
                    type="button"
                    className="btn btn-outline btn-outline-light mt-4"
                    onClick={() => {
                      navigate("/customer-signup");
                    }}
                  >
                    Sign up now
                  </Button> */}
                </Tab.Pane>
              </Tab.Content>
              <Nav variant="pills" id="pills-tab">
                <NavItem role="presentation">
                  <Nav.Link
                    as={Link}
                    to="/customer-signup"
                    active
                    eventKey="first"
                  >
                    <p className="fz28 mb-0">Connect with a Hero</p>
                  </Nav.Link>
                </NavItem>
                {/* <span className="fz28 white">OR</span> */}
                <NavItem role="presentation">
                  <Nav.Link as={Link} to="/hero-home" eventKey="second">
                    <img src="assets/img/h-ic.svg" alt="img" />
                    Become a Hero >>>
                  </Nav.Link>
                </NavItem>
              </Nav>
            </Tab.Container>
          </div>
        </Container>
      </section>

      <section className="yourSafety sec_pd">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={5} sm={12} className="order2">
              <div className="imgWrap text-center">
                <Image src="assets/img/safety.svg"></Image>
              </div>
            </Col>
            <Col md={7} sm={12} className="order1">
              <div className="sectionTitle text-center">
                <h2 className="blue poppins fz50 bold mb-3">
                  Your Hero on Demand!
                </h2>
                <p className="mb-3 fz28 regular poppins black lh35">
                  HERO is an{" "}
                  <span className="blue bold">
                    on-demand personal security app
                  </span>
                  . Scared to walk alone? Need help in a new city? You can
                  request a friendly, pre-screened personal security
                  professional to walk by your side.
                </p>
                <p className="mb-0 fz28 bold poppins blue lh35">
                  For just $1 a minute, everyone can feel safe!
                </p>
                <Button
                  onClick={() => {
                    // navigate("what-hero");
                    navigate("how-it-works");
                  }}
                  className="btn btn-outline btn-outline-primary mT30"
                >
                  Get Protection!
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="weHero sec_pd bglight">
        <Container>
          <div className="sectionTitle text-center">
            <h2 className="black poppins fz36 bold mb-3">
              We all deserve a <span className="text-uppercase">Hero</span>
            </h2>
            <p className="mb-0 fz22 regular poppins black lh33">
              HERO is a revolutionary new on-demand personal bodyguard app.
              <br />
              Quick and cost-effective, it’s a convenient way to request
              <br />
              personal security when you’re traveling around your city.
            </p>
          </div>
          <div className="imgWrap text-center mT70">
            <Image src="assets/img/wehero.svg"></Image>
          </div>
        </Container>
      </section> */}

      {/* <section className="beSafe sec_pd">
        <Container>
          <Row className="align-items-center h-100 ">
            <Col sm={5}>
              <div className="sectionTitle">
                <h2 className="white poppins fz36 bold mb-3">
                  We all deserve a <span className="text-uppercase">Hero</span>
                </h2>
                <p className="mb-0 fz16 regular poppins white lh25">
                  HERO is a revolutionary new on-demand personal bodyguard app.
                  Quick and cost-effective, it’s a convenient way to request
                  personal security when you’re traveling around your city.
                </p>
                <Button
                  onClick={() => {
                    navigate("what-hero");
                  }}
                  className="btn btn-outline btn-outline-light mT30"
                >
                  Learn More
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      <section className="beSafe sec_pd lesscrime">
        <Container>
          <Row className="align-items-center h-100">
            <Col sm={12}>
              <div className="sectionTitle">
                <h2 className="white poppins fz50 bold mb-3">
                  You deserve to feel safe!
                </h2>
                <p className="mb-0 fz28 regular poppins white lh35">
                  The best way to reduce crime is to prevent it!&nbsp;
                  <br></br>
                  Our <b>Heroes prevent crime</b> and <b>reduce assaults</b> by
                  helping users
                  <br></br>
                  from becoming targets. You can always feel safe on the
                  streets.
                </p>

                <Button
                  onClick={() => {
                    // navigate("whywe-need");
                    navigate("what-hero");
                  }}
                  className="btn btn-outline btn-outline-light mT30"
                >
                  Don’t be a Victim!
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="workus">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col sm={5}>
              <div className="imgWrap">
                <Image src="assets/img/workus.png"></Image>
              </div>
            </Col>
            <Col sm={5}>
              <div className="sectionTitle">
                <h2 className="white poppins fz36 bold mb-3">
                  Be a Hero. Work with us.
                </h2>
                <p className="mb-0 fz16 regular poppins white lh25">
                  Do you have what it takes to be a Hero? Register now and earn
                  using your protection skills. Because everyone
                  <br></br>
                  deserves a Hero and that Hero could be you!
                </p>
                <Button
                  onClick={() => {
                    navigate("hero-overview");
                  }}
                  className="btn btn-outline btn-outline-light mT30"
                >
                  Learn More
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}

      <section className="protection sec_pd">
        <Container>
          <Row className="align-items-center  justify-content-center">
            <Col md={7} sm={12}>
              <div className="imgWrap text-center">
                <Image className="w-100" src="assets/img/personal3.png"></Image>
              </div>
            </Col>
            <Col md={5} sm={12}>
              <div className="sectionTitle">
                <h2 className="blue poppins fz50 bold mb-3 lh45">
                  …and request<br></br> your Hero!
                </h2>
                <p className="mb-0 fz28 regular poppins black lh35">
                  Your Hero will be with you within minutes. Heroes are local,
                  friendly, fully vetted, and pre-screened security
                  professionals - available to walk with you for your
                  protection.
                </p>
                <Button
                  onClick={() => {
                    // navigate("/what-hero");
                    navigate("getstarted");
                  }}
                  className="btn btn-outline btn-outline-primary mT30"
                >
                  Connect with a HERO!
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="beSafe sec_pd nycAvailable">
        <Container>
          <p className="white poppins fz70 mb-0 text-center">
            Now available in NYC!
          </p>
        </Container>
      </section>

      {/* <section className="downloadapp_sec  bglight">
        <Container>
          <div className="sectionTitle text-center">
            <h2 className="black poppins fz36 bold mb-3">
              This is just the beginning
            </h2>
          </div>

          <Row className="align-items-center  justify-content-center">
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pR65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="assets/img/logo.svg"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br> the Hero app
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Everyone deserves one
                </p>
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="downloadapp_sec_box pL65">
                <div className="downloadapp_sec_box_inner">
                  <figure>
                    {" "}
                    <Image src="assets/img/logoblue.png"></Image>
                  </figure>
                  <div className="content">
                    <p className="mb-0 fz24 medium poppins black text-center">
                      Download<br></br> I am a Hero 
                    </p>
                  </div>
                  <svg
                    className="rightIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                  </svg>
                </div>
                <p className="mb-0 fz36 bold poppins black text-center mt-2">
                  Become a Hero
                </p>
              </div>
            </Col>
          </Row>

          <p className="mb-0 fz20 regular poppins black text-center ">
            Explore the beta version of our app, before <br></br>our full
            service launch - coming soon.
          </p>
        </Container>
      </section> */}
    </div>
  );
};

export default Home;
