import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const SafetyResources = () => {
  return (
    <div>
      <section className="crimePicture bg-transparent sec_pd bigIcon">
        <Container>
          <div className="sectionTitle mB70">
            <h2 className="black poppins fz48 bold mb-3">Safety Resources</h2>
            <p className="mb-0 fz20 regular poppins black lh30">
              Read our articles about protection and safety and <br></br>tips
              from our community to keep your knowledge <br></br> as honed as
              your body.
            </p>
          </div>
          <Row>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-0">
                <div class="whyHero_Wors_cardcontent pe-lg-5">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/avoiding-conflict.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Avoiding conflict
                    </h4>
                  </div>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <Link className="fz15 regular poppins lh22 black mT30 d-inline-block">
                    Read more
                  </Link>
                </div>
              </div>
            </Col>
            <hr className="d-block d-lg-none mt-4"></hr>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-4 mb-lg-0 mt-4 mt-lg-0">
                <div class="whyHero_Wors_cardcontent pe-lg-5">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/street-awareness.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Street awareness
                    </h4>
                  </div>

                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Read more
                  </Link>
                </div>
              </div>
            </Col>
            <hr className="d-block d-lg-none mt-4"></hr>
            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none ">
                <div class="whyHero_Wors_cardcontent pe-lg-5">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/preventing-violence.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Preventing violence
                    </h4>
                  </div>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                  <Link className="fz15 regular poppins lh22 black text-decoration-none mT30 d-inline-block">
                    Read more
                  </Link>
                </div>
              </div>
            </Col>

            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-0">
                <div class="whyHero_Wors_cardcontent pe-lg-5">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/sign-up.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Sign up for emails
                    </h4>
                  </div>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
            </Col>

            <Col sm={12} md={12} lg={4}>
              <div class="whyHero_Wors_card p-0 shadow-none mb-0">
                <div class="whyHero_Wors_cardcontent pe-lg-5">
                  <div className="whyHero_Wors_cardcontent_top">
                    <Image src="assets/img/sign-up2.svg"></Image>
                    <h4 class="mb-0 fz20 bold poppins mT30 black">
                      Sign up for events
                    </h4>
                  </div>
                  <p class="mb-0 fz15 regular poppins lh22 black">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="safetyLink sec_pd">
        <Container>
          <Row>
            <Col sm={12} lg={6}>
              <Link className="fz30 medium black poppins">
                Our commitment to safety
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </Col>
            <Col sm={12} lg={6}>
              <Link className="fz30 medium black poppins">
                Hero safety
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
                </svg>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default SafetyResources;
