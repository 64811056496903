import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { getHeroSignupProgress, uploadHeroDocs } from "../api/hero";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { addCard } from "../api/payment";
import { profile } from "../api/user";

const CustomerSignupStep3 = () => {
  const navigate = useNavigate();
  const [userCard, setUserCard] = useState();
  const [selectedCard, setSelectedCard] = useState("bankCard");
  const [errorMessage, setErrorMessage] = useState(null);
  //get user uploaded data;
  useEffect(() => {
    profile().then((response) => {
      if (response.data.success) {
        const userCard = response?.data?.data?.user?.card?.[0] || {};
        userCard && setUserCard(userCard);
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      cardNumber: userCard?.cardNumber,
      cardholderName: userCard?.cardholderName,
      cardExpMonthYear: userCard
        ? `${userCard?.cardExpMonth}/${userCard?.cardExpYear}`
        : "",
      cardCVC: userCard?.cardCVC,
      zipCode: userCard?.zipCode,
    },
    validationSchema: yup.object().shape({
      cardNumber: yup
        .number()
        // .test(
        //   "len",
        //   "Please enter valid number",
        //   (val) => val.toString().length === 16
        // )
        .typeError("Please enter valid number")
        .required("Please enter card number"),
      cardholderName: yup.string().required("Please enter card holder name"),
      cardExpMonthYear: yup.string().required("Please enter exp year"),
      cardCVC: yup.string().required("Please enter cvv"),
      zipCode: yup.string().required("Please enter zip code"),
    }),
    onSubmit: async (values) => {
      try {
        const splittedCardExpMonthYear = values?.cardExpMonthYear.split("/");
        const cardExpMonth = splittedCardExpMonthYear?.[0];
        const cardExpYear = splittedCardExpMonthYear?.[1];
        const cardResponse = await addCard({
          cardNumber: values.cardNumber,
          cardholderName: values.cardholderName,
          cardCVC: values.cardCVC,
          zipCode: values.zipCode,
          cardExpMonth,
          cardExpYear,
        });
        if (cardResponse.data.success) {
          setErrorMessage(null);
          navigate("/customer-signup-progress");
        } else {
          setErrorMessage(cardResponse.data?.message);
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  const addGaps = (str) => {
    return str[str.length - 1] === " "
      ? str.substring(0, str.length - 1)
      : str.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");
  };

  const expriy_format = (value) => {
    const expdate = value;
    const expDateFormatter =
      expdate.replace(/\//g, "").substring(0, 2) +
      (expdate.length > 2 ? "/" : "") +
      expdate.replace(/\//g, "").substring(2, 6);

    return expDateFormatter;
  };

  return (
    <div className="customer_signup sec_pd">
      <Container>
        <div className="customer_signup_process paymentmethod">
          <div className="processTitle black medium fz20 poppins text-center mB20">
            <NavLink
              className="d-flex align-items-center text-decoration-none text-dark"
              to="/customer-signup-progress"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M14 18L8 12L14 6"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>{" "}
              Back
            </NavLink>
            Add payment method{" "}
          </div>
          <div className="downloadApp_div m-auto text-center mt-5">
            <div className="cardRow d-flex justify-content-center">
              <Image
                className={`card-img ${
                  selectedCard === "bankCard" ? "active" : ""
                }`}
                src="assets/img/bankcard.png"
                onClick={() => setSelectedCard("bankCard")}
              />
              <Image
                className={`card-img ${
                  selectedCard === "appleCard" ? "active" : ""
                }`}
                src="assets/img/apple-pay.png"
                onClick={() => setSelectedCard("appleCard")}
              />
            </div>
            <div className="cardData">
              <Form>
                <Form.Group className="mb-3" controlId="cardNumber">
                  <Form.Label className="fz16 medium poppins d-block text-start">
                    {" "}
                    Card Number{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="xxxx xxxx xxxx xxxx"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "cardNumber",
                        addGaps(e.target.value)
                      );
                    }}
                    name="cardNumber"
                    onBlur={formik.handleBlur}
                    value={formik.values.cardNumber}
                  />
                  {formik.touched.cardNumber && formik.errors.cardNumber ? (
                    <small className="text-danger d-flex">
                      {formik.errors.cardNumber}
                    </small>
                  ) : null}
                </Form.Group>
                <Row>
                  <Col sm={12} md={7}>
                    <Form.Group className="mb-3" controlId="cardholderName">
                      <Form.Label className="fz16 medium poppins d-block text-start">
                        {" "}
                        Cardholder Name{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cardholderName}
                      />
                      {formik.touched.cardholderName &&
                      formik.errors.cardholderName ? (
                        <small className="text-danger d-flex text-start">
                          {formik.errors.cardholderName}
                        </small>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={5}>
                    <Form.Group className="mb-3" controlId="zipCode">
                      <Form.Label className="fz16 medium poppins d-block text-start">
                        {" "}
                        Zip Code{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter ZIP Code"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.zipCode}
                      />
                      {formik.touched.zipCode && formik.errors.zipCode ? (
                        <small className="text-danger d-flex text-start">
                          {formik.errors.zipCode}
                        </small>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={7}>
                    <Form.Group className="mb-3" controlId="cardExpMonthYear">
                      <Form.Label className="fz16 medium poppins d-block text-start">
                        Expiration Date{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="mm/yyyy"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={expriy_format(formik.values.cardExpMonthYear)}
                      />
                      {formik.touched.cardExpMonthYear &&
                      formik.errors.cardExpMonthYear ? (
                        <small className="text-danger d-flex text-start">
                          {formik.errors.cardExpMonthYear}
                        </small>
                      ) : null}
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={5}>
                    <Form.Group className="mb-3" controlId="cardCVC">
                      <Form.Label className="fz16 medium poppins d-block text-start">
                        {" "}
                        CVC{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="xxx"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.cardCVC}
                      />
                      {formik.touched.cardCVC && formik.errors.cardCVC ? (
                        <small className="text-danger d-flex text-start">
                          {formik.errors.cardCVC}
                        </small>
                      ) : null}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </div>
            {errorMessage && (
              <small className="d-block text-danger mb-3">{errorMessage}</small>
            )}
            <div className="btnRow">
              <Button
                variant="primary"
                className="btn btn-primary w-100 mb-3"
                onClick={formik.handleSubmit}
              >
                Add Card
              </Button>
              <NavLink
                className="d-flex align-items-center text-decoration-none text-dark"
                to="/customer-signup-progress"
              >
                <Button
                  variant="primary-outline"
                  className="btn btn-outline-primary w-100"
                >
                  Choose Later
                </Button>
              </NavLink>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default CustomerSignupStep3;

