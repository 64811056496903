import React from "react";
import { Container, Image, Col, Row, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

const CompanyTeam = () => {
  return (
    <div>
      <div className="innerHeader d-none d-lg-block">
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand as={Link} to="#home" className="bold poppins black">
              Company
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link as={Link} to="/company-founder">
                  About us
                </Nav.Link>
                {/* <Nav.Link as={Link} to="/how-it-works" className="active">
                  Our Team
                </Nav.Link> */}
                {/* <Nav.Link as={Link} to="fees">News</Nav.Link>
                <Nav.Link as={Link} to="getstarted">Blog</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <section className="sec_pd company_team">
        <Container>
          <Link className="fz14 regular poppins lh22 black  mB30 d-inline-block d-block d-lg-none">
            Back to about us
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="20"
              height="20"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z" />
            </svg>
          </Link>
          <h4 className="fz40 bold poppins black team_title mB60">
            Meet the Hero team
          </h4>

          <Row>
            <Col sm={12} md={4}>
              <div className="teamData">
                <Image className="w-100" src="assets/img/besafe.png"></Image>
                <div className="teamContent mT50">
                  <div className="userName mB20">
                    <p class="mb-0 fz18 regular poppins black">Jorge Ramos</p>
                    <span class="mb-0 fz18 bold poppins black">CEO</span>
                  </div>
                  <p class="mb-0 fz14 regular poppins lh25 black">
                    Mr Ramos is the primary founder and developer of the Hero
                    concept to deliver immediate, affordable personal security
                    to people of all backgrounds on demand. In essence, Mr Ramos
                    identified a gap in the market—while people of considerable
                    means often engage commercial security services on a
                    continuous or occasional basis, these services are beyond
                    the reach, although highly needed, of most people in large
                    metropolitan areas that might feel insecure in certain
                    circumstances, such as walking home from a late-night
                    meeting or attending an appointment in unfamiliar areas. Mr
                    Ramos is currently spending all of his productive time on
                    the development and implementation of the Hero concept,
                    commencing with its first roll-out in New York City.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="teamData">
                <Image className="w-100" src="assets/img/besafe.png"></Image>
                <div className="teamContent mT50">
                  <div className="userName mB20">
                    <p class="mb-0 fz18 regular poppins black"> Mark Mendel</p>
                    <span class="mb-0 fz18 bold poppins black">
                      Legal and supervising services
                    </span>
                  </div>
                  <p class="mb-0 fz14 regular poppins lh25 black">
                    Mr Mendel is general counsel for Interceramic and its
                    affiliates, a position he has held since 2016. Prior to
                    that, he was in private practise representing a variety of
                    clients (including Interceramic, a relationship that began
                    in 1985) in business transactions, including mergers and
                    acquisitions; private and public offerings of debt and
                    equity securities; registered public offerings on the New
                    York Stock Exchange; sophisticated secured transactions;
                    cross-border financings; venture capital and private equity;
                    commercial and industrial leasing and public finance for
                    governmental entities.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="teamData">
                <Image className="w-100" src="assets/img/besafe.png"></Image>
                <div className="teamContent mT50">
                  <div className="userName mB20">
                    <p class="mb-0 fz18 regular poppins black"> Jaime Palmer</p>
                    <span class="mb-0 fz18 bold poppins black">
                      Business and investment opportunities
                    </span>
                  </div>
                  <p class="mb-0 fz14 regular poppins lh25 black">
                    Mr Palmer is a Managing Member and Founder of View Capital
                    Advisors, LLC. Mr Palmer has been in the finance, wealth and
                    asset management industry since 1998.{" "}
                  </p>
                  <p class="mb-0 fz14 regular poppins lh25 black">
                    View Capital Advisors, LLC was founded in 2004 and since
                    then Mr Palmer has been involved in the firm’s various areas
                    of operations including investment banking and corporate
                    finance, debt and equity placements, the oversight of their
                    asset management platform which included two publicly listed
                    Irish mutual funds through the firm’s Sollertia Funds
                    platform and managing high net worth and institutional
                    client assets.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="teamData mT90">
                <Image className="w-100" src="assets/img/besafe.png"></Image>
                <div className="teamContent mT50">
                  <div className="userName mB20">
                    <p class="mb-0 fz18 regular poppins black">
                      {" "}
                      Alejandro González-Padilla
                    </p>
                    <span class="mb-0 fz18 bold poppins black">
                      Marketing & Advertising Strategies
                    </span>
                  </div>
                  <p class="mb-0 fz14 regular poppins lh25 black">
                    Mr González-Padilla is a marketing consultant and
                    cinematographer/director. He is the founder of XMARCA S.A.
                    de C.V. (México 1993) and SKY SEA FILMS, LLC (USA 2012),
                    both of which are currently active.
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={12} md={4}>
              <div className="teamData mT90">
                <Image className="w-100" src="assets/img/besafe.png"></Image>
                <div className="teamContent mT50">
                  <div className="userName mB20">
                    <p class="mb-0 fz18 regular poppins black">
                      {" "}
                      Victor Almeida
                    </p>
                    <span class="mb-0 fz18 bold poppins black">
                      Business Advice and investor
                    </span>
                  </div>
                  <p class="mb-0 fz14 regular poppins lh25 black">
                    Victor Almeida is Chairman and Chief Executive Officer of
                    Internacional de Cerámica, S.A.B. de C.V. (“Interceramic”),
                    a public company listed on the Mexican Stock Exchange.
                    Interceramic and its subsidiaries is today one of the
                    largest manufacturers and distributors of ceramic tile,
                    stone, installation materials and related products in
                    Mexico, the United States, Central America and China.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default CompanyTeam;

