import base from "./base";

export const getTopics = (payload = {}) => {
  const { page = 1, perPage = 5, type = "Customer" } = payload;

  return base.get(
    `/utils/topic?page=${page}&perPage=${perPage}&topicType=${type}`
  );
};

export const getSubTopics = (payload = "") =>
  base.get(`/utils/sub-topic/${payload}`);
